import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

class IconRanges extends Component {

    constructor(props){
        super(props);

        this.blogID = props.blogID
        this.local = props.location

        this.state = {
            icons: props.icons,
            loading: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getIconsOriginal = this.getIconsOriginal.bind(this);
    }

    async getIconsOriginal(){
      
        const getIcons = `query GetIcons {
          getBlog(id:"${this.blogID}") {
          id
          location
          icons {
            items {
              id
              value
              lowest
              low
              high
              highest
              order
            }
          }
        }
        }`
      
        this.icondata = await API.graphql(graphqlOperation(getIcons));
        this.modIcons = this.icondata.data.getBlog.icons.items;
        this.orderedIcons =this.modIcons.sort(function(a, b) {
            return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
        });
    
       return this.orderedIcons
      }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }



    async handleSubmit(event) {
        event.preventDefault();

        let low = 'null'
        let high = 'null'

        let order = parseFloat(event.target.order.value);
        let name = event.target.value.value;
        let hours = event.target.hours.value
        let minutes = event.target.minutes.value
        let lowest = event.target.lowest.value
        let highest = event.target.highest.value

        if(hours !== ''){
            let hoursTest = parseFloat(hours)
            if(isNaN(hoursTest)){
              alert("hours must be a number")
              return
            };
        };
        if(hours === ''){
            hours = 0
        }

        if(minutes !== ''){
            let minutesTest = parseFloat(minutes)
            if(isNaN(minutesTest)){
              alert("minutes must be a number")
              return
            };
        };        
        if(minutes === ''){
            minutes = 0
        }

        if(lowest !== ''){
            let lowestTest = parseFloat(lowest)
            if(isNaN(lowestTest)){
              alert("below alarm must be a number")
              return
            };
        };
        if(lowest === ''){
            lowest = 0
        }

        if(highest !== ''){
            let highestTest = parseFloat(highest)
            if(isNaN(highestTest)){
              alert("above alarm must be a number")
              return
            };
        };
        if(highest === ''){
            highest = 0
        }

        let simpleClosure = event.target.simpleClose.value
        if(simpleClosure === 'true'){                
            if(!this.state.closureAlarm5) {
                // open alarm
                highest = 1
                lowest = .55555
              } else if(this.state.closureAlarm5) {
                // closed alarm
                highest = .55555
                lowest = 0
              } else {
                console.log('error setting closure alarm settings')
                return
              }
            
        } 

        if(isNaN(order)){
            order = null;
        }
        if(isNaN(hours) || isNaN(minutes)){
            alert('time must be in numbers')
            return
        }


        const update = `mutation UpdateIcon {
            updateIcon(input: {
                id: "${event.target.id.value}"
                value:"${name}"
                lowest: ${lowest}
                low: ${low}
                high: ${high}
                highest: ${highest}
                order: ${order}
                iconBlogId: "${this.blogID}"
            }){
                id
                value
                lowest
                low
                high
                highest
                order
            }
        }`

        try {
            this.addedIcon = API.graphql(graphqlOperation(update));
        } catch(error) {
            alert('error occurred updating icon settings')
            console.log(error)
        }

        if(event.target.input.value === '1'){
            let alarm1Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.an1AlarmTime = alarm1Time.toString()
            this.jsonCurrent.an1AlarmLow = lowest.toString()
            this.jsonCurrent.an1AlarmHigh = highest.toString()
        }
        if(event.target.input.value === '2'){
            let alarm2Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.an2AlarmTime = alarm2Time.toString()
            this.jsonCurrent.an2AlarmLow = lowest.toString()
            this.jsonCurrent.an2AlarmHigh = highest.toString()
        }
        if(event.target.input.value === '3'){
            let alarm3Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.an3AlarmTime = alarm3Time.toString()
            this.jsonCurrent.an3AlarmLow = lowest.toString()
            this.jsonCurrent.an3AlarmHigh = highest.toString()
        }
        if(event.target.input.value === '4'){
            let alarm4Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.an4AlarmTime = alarm4Time.toString()
            this.jsonCurrent.an4AlarmLow = lowest.toString()
            this.jsonCurrent.an4AlarmHigh = highest.toString()
        }
        if(event.target.input.value === '5'){
            let alarm5Time = ((Number(event.target.hours.value) * 3600) + (Number(event.target.minutes.value) * 60)) * 2;
            this.jsonCurrent.dig5AlarmTime = alarm5Time.toString()
            if(this.jsonCurrent.accClose5 === 'false' && this.jsonCurrent.digan5 === 'true'){
                if(!this.state.closureAlarm5) {
                    // open alarm
                    this.jsonCurrent.dig5AlarmHigh = '1'
                    this.jsonCurrent.dig5AlarmLow = '.55555'
                  } else {
                    // closed alarm
                    this.jsonCurrent.dig5AlarmHigh = '.55555'
                    this.jsonCurrent.dig5AlarmLow = '0'
                  } 
            } else {
                this.jsonCurrent.dig5AlarmLow = lowest.toString()
                this.jsonCurrent.dig5AlarmHigh = highest.toString()
            }
        }

        if(event.target.input.value === '6'){
            let alarm6Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.dig6AlarmTime = alarm6Time.toString()
            if(this.jsonCurrent.accClose6 === 'false' && this.jsonCurrent.digan6 === 'true'){
                if(!this.state.closureAlarm6) {
                    // open alarm
                    this.jsonCurrent.dig6AlarmHigh = '1'
                    this.jsonCurrent.dig6AlarmLow = '.55555'
                  } else {
                    // closed alarm
                    this.jsonCurrent.dig6AlarmHigh = '.55555'
                    this.jsonCurrent.dig6AlarmLow = '0'
                  } 
            } else {
                this.jsonCurrent.dig6AlarmLow = lowest.toString()
                this.jsonCurrent.dig6AlarmHigh = highest.toString()
            }
        }
        if(event.target.input.value === '7'){
            let alarm7Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.dig7AlarmTime = alarm7Time.toString()
            if(this.jsonCurrent.accClose7 === 'false' && this.jsonCurrent.digan7 === 'true'){
                if(!this.state.closureAlarm7) {
                    // open alarm
                    this.jsonCurrent.dig7AlarmHigh = '1'
                    this.jsonCurrent.dig7AlarmLow = '.55555'
                  } else {
                    // closed alarm
                    this.jsonCurrent.dig7AlarmHigh = '.55555'
                    this.jsonCurrent.dig7AlarmLow = '0'
                  } 
            } else {
                this.jsonCurrent.dig7AlarmLow = lowest.toString()
                this.jsonCurrent.dig7AlarmHigh = highest.toString()
            }
        }
        if(event.target.input.value === '8'){
            let alarm8Time = ((Number(hours) * 3600) + (Number(minutes) * 60)) * 2;
            this.jsonCurrent.dig8AlarmTime = alarm8Time.toString()
            if(this.jsonCurrent.accClose8 === 'false' && this.jsonCurrent.digan8 === 'true'){
                if(!this.state.closureAlarm8) {
                    // open alarm
                    this.jsonCurrent.dig8AlarmHigh = '1'
                    this.jsonCurrent.dig8AlarmLow = '.55555'
                  } else {
                    // closed alarm
                    this.jsonCurrent.dig8AlarmHigh = '.55555'
                    this.jsonCurrent.dig8AlarmLow = '0'
                  } 
            } else {
                this.jsonCurrent.dig8AlarmLow = lowest.toString()
                this.jsonCurrent.dig8AlarmHigh = highest.toString()
            }
        }



        let body = this.jsonCurrent

        const myInit = {
            body
        };

        function setData() {
            const apiName = 'ShadowApi';
            const path = '/shadow';
        
            return API.put(apiName, path, myInit);
          }
        
          try {
            await setData();
          } catch(error) {
            console.log(error)
            alert('error occured programming device check your internet connection and try again')
          }
               
          alert("device settings updated");
        
    }


    async componentDidMount() {
        this.setState({loading: true})

        let iconsOriginal = await this.getIconsOriginal()

        const local = this.local;
    
        function getData() {
            const apiName = 'ShadowApi';
            const path = '/shadow';
            const myInit = {
            queryStringParameters: {
                location: local
            }
        };
            return API.get(apiName, path, myInit);
        }
        
        
        // get current shadow and if no shadow found return blank form
        let response
        
        try{
            response = await getData();
        }
        catch(err){
            this.setState({
                loading: false
            });
            return
        }

        let jsonData = response.success;
        let jsonString = JSON.parse(jsonData.payload);
        let jsonState = jsonString.state.desired;
        this.jsonCurrent = jsonState
        this.jsonCurrent.location = this.local

        iconsOriginal.forEach(icon => {
            if(icon.value === jsonState.an1Name){
                icon.input = 1
                let an1AlarmFullTime = Number(jsonState['an1AlarmTime']) / 2;
                let an1Hour = Math.floor(an1AlarmFullTime / 3600);
                let an1Min = Math.floor(an1AlarmFullTime % 3600 / 60);
                icon.hours = an1Hour
                icon.minutes = an1Min
            }
            if(icon.value === jsonState.an2Name){
                icon.input = 2
                let an2AlarmFullTime = Number(jsonState['an2AlarmTime']) / 2;
                let an2Hour = Math.floor(an2AlarmFullTime / 3600);
                let an2Min = Math.floor(an2AlarmFullTime % 3600 / 60);
                icon.hours = an2Hour
                icon.minutes = an2Min
            }
            if(icon.value === jsonState.an3Name){
                icon.input = 3
                let an3AlarmFullTime = Number(jsonState['an3AlarmTime']) / 2;
                let an3Hour = Math.floor(an3AlarmFullTime / 3600);
                let an3Min = Math.floor(an3AlarmFullTime % 3600 / 60);
                icon.hours = an3Hour
                icon.minutes = an3Min
            }
            if(icon.value === jsonState.an4Name){
                icon.input = 4
                let an4AlarmFullTime = Number(jsonState['an4AlarmTime']) / 2;
                let an4Hour = Math.floor(an4AlarmFullTime / 3600);
                let an4Min = Math.floor(an4AlarmFullTime % 3600 / 60);
                icon.hours = an4Hour
                icon.minutes = an4Min
            }
            if(icon.value === jsonState.dig5Name){
                icon.input = 5
                let dig5AlarmFullTime = Number(jsonState['dig5AlarmTime']) / 2;
                let dig5Hour = Math.floor(dig5AlarmFullTime / 3600);
                let dig5Min = Math.floor(dig5AlarmFullTime % 3600 / 60);
                icon.hours = dig5Hour
                icon.minutes = dig5Min
                if(jsonState.accClose5 === 'false' && jsonState.digan5 === 'true'){
                    icon.simpleClose = true
                    if(Number(jsonState['dig5AlarmLow']) === .55555){
                        icon.highest = 1
                        icon.lowest = .55555
                        this.setState({
                          closureAlarm5: false
                        })
                      }
                      if(Number(jsonState['dig5AlarmHigh']) === .55555){
                        icon.highest = .55555
                        icon.lowest = 0
                        this.setState({
                          closureAlarm5: true
                        })
                      }
                }
            }
            if(icon.value === jsonState.dig6Name){
                icon.input = 6
                let dig6AlarmFullTime = Number(jsonState['dig6AlarmTime']) / 2;
                let dig6Hour = Math.floor(dig6AlarmFullTime / 3600);
                let dig6Min = Math.floor(dig6AlarmFullTime % 3600 / 60);
                icon.hours = dig6Hour
                icon.minutes = dig6Min
                if(jsonState.accClose6 === 'false' && jsonState.digan6 === 'true'){
                    icon.simpleClose = true
                    if(Number(jsonState['dig6AlarmLow']) === .55555){
                        icon.highest = 1
                        icon.lowest = .55555
                        this.setState({
                          closureAlarm6: false
                        })
                      }
                      if(Number(jsonState['dig6AlarmHigh']) === .55555){
                        icon.highest = .55555
                        icon.lowest = 0
                        this.setState({
                          closureAlarm6: true
                        })
                      }
                }
            }
            if(icon.value === jsonState.dig7Name){
                icon.input = 7
                let dig7AlarmFullTime = Number(jsonState['dig7AlarmTime']) / 2;
                let dig7Hour = Math.floor(dig7AlarmFullTime / 3600);
                let dig7Min = Math.floor(dig7AlarmFullTime % 3600 / 60);
                icon.hours = dig7Hour
                icon.minutes = dig7Min
                if(jsonState.accClose7 === 'false' && jsonState.digan7 === 'true'){
                    icon.simpleClose = true
                    if(Number(jsonState['dig7AlarmLow']) === .55555){
                        icon.highest = 1
                        icon.lowest = .55555
                        this.setState({
                          closureAlarm7: false
                        })
                      }
                      if(Number(jsonState['dig7AlarmHigh']) === .55555){
                        icon.highest = .55555
                        icon.lowest = 0
                        this.setState({
                          closureAlarm7: true
                        })
                      }
                }
            }
            if(icon.value === jsonState.dig8Name){
                icon.input = 8
                let dig8AlarmFullTime = Number(jsonState['dig8AlarmTime']) / 2;
                let dig8Hour = Math.floor(dig8AlarmFullTime / 3600);
                let dig8Min = Math.floor(dig8AlarmFullTime % 3600 / 60);
                icon.hours = dig8Hour
                icon.minutes = dig8Min

                if(jsonState.accClose8 === 'false' && jsonState.digan8 === 'true'){
                    icon.simpleClose = true
                    if(Number(jsonState['dig8AlarmLow']) === .55555){
                        icon.highest = 1
                        icon.lowest = .55555
                        this.setState({
                          closureAlarm8: false
                        })
                      }
                      if(Number(jsonState['dig8AlarmHigh']) === .55555){
                        icon.highest = .55555
                        icon.lowest = 0
                        this.setState({
                          closureAlarm8: true
                        })
                      }
                }
            }

        })

        this.setState({
            icons: iconsOriginal,
            loading: false
        })
        
    }

    render(){
        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        var imageStop = require('../Nowledge-App-Icons-STOP.png')

        return (
        <div>
            {this.state.loading &&
                <div className="Loading-button">Loading....</div>
            }
            {
                this.state.icons.map((icon,index) => (
                    <form key={icon.id} onSubmit={this.handleSubmit}>
                        <div className="Icon-label">{icon.value.replace(/_/g, ' ')}</div>
                        <br/>
                        <input type="hidden" name="value" value={icon.value} />
                        <input type="hidden" name="id" value={icon.id} />
                        <input type="hidden" name="input" value={icon.input} />

                        <br/>
                        {!icon.simpleClose &&
                            <div>
                                <input type="hidden" name="simpleClose" value={false}/>
                                <div className="Icon-orderlabel">Alert when reading is:</div>
                                <div className="Iconemo-containter">
                            <img className="Icon-emoji2" src={imageStop} alt="stop"/>
                            <img className="Icon-emoji2" src={imageCheck} alt="check" />
                            <img className="Icon-emoji2" src={imageStop} alt="stop" />
                        </div>
                                <div>
                                    <div className="form-text-labels2">below</div>
                                    <div className="form-text-labels2">above</div>
                                </div>
                                <input type="number" step="0.01" className="Icon-box-form2" name="lowest" defaultValue={icon.lowest} value={this.state.icons.lowest} onChange={this.handleChange} />
                                <input type="number" step="0.01" className="Icon-box-form2" name="highest" defaultValue={icon.highest} value={this.state.icons.highest} onChange={this.handleChange} />
                            </div>
                        }
                        {icon.simpleClose && icon.input === 5 &&
                            <div>
                                <input type="hidden" name="lowest" value={icon.lowest} />
                                <input type="hidden" name="highest" value={icon.highest} />
                                <input type="hidden" name="simpleClose" value={icon.simpleClose}/>
                                <div className="Icon-orderlabel">Send alarm when contact is:</div>
                                <br />
                                <div>
                                <div className="form-text-labels2">open(0)</div>
                                <div className="form-text-labels2">closed(1)</div>
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm5' onChange={(e) => {this.setState({closureAlarm5: !this.state.closureAlarm5})}} checked={!this.state.closureAlarm5} />&nbsp;
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm5' onChange={(e) => {this.setState({closureAlarm5: !this.state.closureAlarm5})}} checked={this.state.closureAlarm5} />
                                </div>
                            </div>
                        }
                        {icon.simpleClose && icon.input === 6 &&
                            <div>
                                <input type="hidden" name="lowest" value={icon.lowest} />
                                <input type="hidden" name="highest" value={icon.highest} />
                                <input type="hidden" name="simpleClose" value={icon.simpleClose}/>
                                <div className="Icon-orderlabel">Send alarm when contact is:</div>
                                <br />
                                <div>
                                <div className="form-text-labels2">open(0)</div>
                                <div className="form-text-labels2">closed(1)</div>
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm6' onChange={(e) => {this.setState({closureAlarm6: !this.state.closureAlarm6})}} checked={!this.state.closureAlarm6} />&nbsp;
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm6' onChange={(e) => {this.setState({closureAlarm6: !this.state.closureAlarm6})}} checked={this.state.closureAlarm6} />
                                </div>
                            </div>
                        }
                        {icon.simpleClose && icon.input === 7 &&
                            <div>
                                <input type="hidden" name="lowest" value={icon.lowest} />
                                <input type="hidden" name="highest" value={icon.highest} />
                                <input type="hidden" name="simpleClose" value={icon.simpleClose}/>
                                <div className="Icon-orderlabel">Send alarm when contact is:</div>
                                <br />
                                <div>
                                <div className="form-text-labels2">open(0)</div>
                                <div className="form-text-labels2">closed(1)</div>
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm7' onChange={(e) => {this.setState({closureAlarm7: !this.state.closureAlarm7})}} checked={!this.state.closureAlarm7} />&nbsp;
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm7' onChange={(e) => {this.setState({closureAlarm7: !this.state.closureAlarm7})}} checked={this.state.closureAlarm7} />
                                </div>
                            </div>
                        }
                        {icon.simpleClose && icon.input === 8 &&
                            <div>
                                <input type="hidden" name="lowest" value={icon.lowest} />
                                <input type="hidden" name="highest" value={icon.highest} />
                                <input type="hidden" name="simpleClose" value={icon.simpleClose}/>
                                <div className="Icon-orderlabel">Send alarm when contact is:</div>
                                <br />
                                <div>
                                <div className="form-text-labels2">open(0)</div>
                                <div className="form-text-labels2">closed(1)</div>
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm8' onChange={(e) => {this.setState({closureAlarm8: !this.state.closureAlarm8})}} checked={!this.state.closureAlarm8} />&nbsp;
                                </div>
                                <div className="form-text-labels2">
                                <input type='checkbox' name='closureAlarm8' onChange={(e) => {this.setState({closureAlarm8: !this.state.closureAlarm8})}} checked={this.state.closureAlarm8} />
                                </div>
                            </div>
                        }
                        <div className="Two-Space"/>
                        <div className="Icon-orderlabel">Time in alarm before sending:</div>
                        <div>
                            <div className="form-text-labels2">hours</div>
                            <div className="form-text-labels2">minutes</div>
                        </div>
                        <input type="number" step="0.01" className="Icon-box-form2" name="hours" defaultValue={icon.hours} value={this.state.icons.hours} onChange={this.handleChange} />
                        <input type="number" step="0.01" className="Icon-box-form2" name="minutes" defaultValue={icon.minutes} value={this.state.icons.minutes} onChange={this.handleChange} />
                        <div className="Icon-orderlabel">Display Order:</div>
                        <br/>
                        <input type="number" className="Icon-box-form2" name="order" defaultValue={icon.order} value={this.state.icons.order} onChange={this.handleChange} />
                        <br/>
                        <input type="submit" className="Add-button" value="submit"/>
                        <div className='Two-space'/>
                        <div className='Two-space'/>
                    </form>
                ))
            }
        </div>
        )
    }
}

export default IconRanges
