import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
//import * as mutations from '../graphql/mutations';
import { deleteIcon } from '../graphql/mutations';
//import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
//import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';

class DeviceSet extends Component {

  constructor(props){
    super(props);

    //catch from settings page
    const {loc} = props.location.state
    this.local = loc
    const {title} = props.location.state
    this.title = title
    const {org} = props.location.state
    this.org = org
    const {version} = props.location.state
    this.version = version

    if(this.local === ""){
      this.local = 'unnamed'
    }

    this.loggedIn = ''
    this.blogid = ''

    // set initial states
    this.state = {
      loading: true,
      deviceAlertName: '',
      an1: false,
      an2: false,
      an3: false,
      an4: false,
      dig5: false,
      dig6: false,
      dig7: false,
      dig8: false,
      digan5: true,
      digan6: true,
      digan7: true,
      digan8: true,
      accClose5: false,
      accClose6: false,
      accClose7: false,
      accClose8: false,
      alarmOn: true,
      an1Name: '',
      an1Low: 0,
      an1High: 0,
      an1AddSub: true,
      an1Scale: 0,
      an1Round: 0,
      an1Offset: 0,
      an1ScaleLow: 0,
      an1ScaleHigh: 0,
      an2Name: '',
      an2Low: 0,
      an2High: 0,
      an2AddSub: true,
      an2Scale: 0,
      an2Round: 0,
      an2Offset: 0,
      an2ScaleLow: 0,
      an2ScaleHigh: 0,
      an3Name: '',
      an3Low: 0,
      an3High: 0,
      an3AddSub: true,
      an3Scale: 0,
      an3Round: 0,
      an3Offset: 0,
      an3ScaleLow: 0,
      an3ScaleHigh: 0,
      an4Name: '',
      an4Low: 0,
      an4High: 0,
      an4Scale: 0,
      an4Round: 0,
      an4AddSub: true,
      an4Offset: 0,
      an4ScaleLow: 0,
      an4ScaleHigh: 0,
      dig5Name: '',
      dig5Bounce: 0,
      dig5BounceHour: 0,
      dig5BounceMin: 0,
      dig5BounceSec: 10,
      dig5Value: 1,
      dig5Reset: false,
      dig5Closed: 1,
      dig5Open: 0,
      digan5Low: 0,
      digan5High: 0,
      digan5Scale: 0,
      digan5Round: 0,
      dig5AddSub: true,
      digan5Offset: 0,
      digan5ScaleLow: 0,
      digan5ScaleHigh: 0,
      dig6Name: '',
      dig6Bounce: 0,
      dig6BounceHour: 0,
      dig6BounceMin: 0,
      dig6BounceSec: 10,
      dig6Value: 1,
      dig6Reset: false,
      dig6Closed: 1,
      dig6Open: 0,
      digan6Low: 0,
      digan6High: 0,
      digan6Scale: 0,
      digan6Round: 0,
      dig6AddSub: true,
      digan6Offset: 0,
      digan6ScaleLow: 0,
      digan6ScaleHigh: 0,
      dig7Name: '',
      dig7Bounce: 0,
      dig7BounceHour: 0,
      dig7BounceMin: 0,
      dig7BounceSec: 10,
      dig7Value: 1,
      dig7Reset: false,
      dig7Closed: 1,
      dig7Open: 0,
      digan7Low: 0,
      digan7High: 0,
      digan7Scale: 0,
      digan7Round: 0,
      dig7AddSub: true,
      digan7Offset: 0,
      digan7ScaleLow: 0,
      digan7ScaleHigh: 0,
      dig8Name: '',
      dig8Bounce: 0,
      dig8BounceHour: 0,
      dig8BounceMin: 0,
      dig8BounceSec: 10,
      dig8Value: 1,
      dig8Reset: false,
      dig8Closed: 1,
      dig8Open: 0,
      digan8Low: 0,
      digan8High: 0,
      digan8Scale: 0,
      digan8Round: 0,
      dig8AddSub: true,
      digan8Offset: 0,
      digan8ScaleLow: 0,
      digan8ScaleHigh: 0,
      an1Alarm: true,
      an2Alarm: true,
      an3Alarm: true,
      an4Alarm: true,
      dig5Alarm: true,
      dig6Alarm: true,
      dig7Alarm: true,
      dig8Alarm: true,
      an1AlarmLow: 0,
      an1AlarmHigh: 0,
      an1AlarmTime: 0,
      an1AlarmHour: 0,
      an1AlarmMin: 0,
      an2AlarmLow: 0,
      an2AlarmHigh: 0,
      an2AlarmTime: 0,
      an2AlarmHour: 0,
      an2AlarmMin: 0,
      an3AlarmLow: 0,
      an3AlarmHigh: 0,
      an3AlarmTime: 0,
      an3AlarmHour: 0,
      an3AlarmMin: 0,
      an4AlarmLow: 0,
      an4AlarmHigh: 0,
      an4AlarmTime: 0,
      an4AlarmHour: 0,
      an4AlarmMin: 0,
      dig5AlarmLow: 0,
      dig5AlarmHigh: 0,
      dig5AlarmTime: 0,
      dig5AlarmHour: 0,
      dig5AlarmMin: 0,
      dig6AlarmLow: 0,
      dig6AlarmHigh: 0,
      dig6AlarmTime: 0,
      dig6AlarmHour: 0,
      dig6AlarmMin: 0,
      dig7AlarmLow: 0,
      dig7AlarmHigh: 0,
      dig7AlarmTime: 0,
      dig7AlarmHour: 0,
      dig7AlarmMin: 0,
      dig8AlarmLow: 0,
      dig8AlarmHigh: 0,
      dig8AlarmTime: 0,
      dig8AlarmHour: 0,
      dig8AlarmMin: 0,
      closureAlarm5: true,
      closureAlarm6: true,
      closureAlarm7: true,
      closureAlarm8: true
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleShadow = this.handleShadow.bind(this);
  }

  // handle all state changes

  handleChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
  }



// submit shadow settings
async handleShadow(event){
  event.preventDefault();


  // validation catches
  if(this.state.an1Low !== ''){
    this.state.an1Low = parseFloat(this.state.an1Low)
    if(isNaN(this.state.an1Low)){
      this.state.an1Low = ''
      alert("analog input 1 low is not a number")
      return
    };
  };
  if(this.state.an1Low === ''){
    this.state.an1Low = 0
  }

  if(this.state.an2Low !== ''){
    this.state.an2Low = parseFloat(this.state.an2Low)
    if(isNaN(this.state.an2Low)){
      this.state.an2Low = ''
      alert("analog input 2 low is not a number")
      return
    };
  };
  if(this.state.an2Low === ''){
    this.state.an2Low = 0
  }

  if(this.state.an3Low !== ''){
    this.state.an3Low = parseFloat(this.state.an3Low)
    if(isNaN(this.state.an3Low)){
      this.state.an3Low = ''
      alert("analog input 3 low is not a number")
      return
    };
  };
  if(this.state.an3Low === ''){
    this.state.an3low = 0
  }

  if(this.state.an4Low !== ''){
    this.state.an4Low = parseFloat(this.state.an4Low)
    if(isNaN(this.state.an4Low)){
      this.state.an4Low = ''
      alert("analog input 4 low is not a number")
      return
    };
  };
  if(this.state.an4Low === ''){
    this.state.an4Low = 0
  }

  if(this.state.digan5Low !== ''){
    this.state.digan5Low = parseFloat(this.state.digan5Low)
    if(isNaN(this.state.digan5Low)){
      this.state.digan5Low = ''
      alert("dig/an input 5 low is not a number")
      return
    };
  };
  if(this.state.digan5Low === ''){
    this.state.digan5Low = 0
  }

  if(this.state.digan6Low !== ''){
    this.state.digan6Low = parseFloat(this.state.digan6Low)
    if(isNaN(this.state.digan6Low)){
      this.state.digan6Low = ''
      alert("dig/an input 6 low is not a number")
      return
    };
  };
  if(this.state.digan6Low === ''){
    this.state.digan6Low = 0
  }

  if(this.state.digan7Low !== ''){
    this.state.digan7Low = parseFloat(this.state.digan7Low)
    if(isNaN(this.state.digan7Low)){
      this.state.digan7Low = ''
      alert("dig/an input 7 low is not a number")
      return
    };
  };
  if(this.state.digan7Low === ''){
    this.state.digan7Low = 0
  }

  if(this.state.digan8Low !== ''){
    this.state.digan8Low = parseFloat(this.state.digan8Low)
    if(isNaN(this.state.digan8Low)){
      this.state.digan8Low = ''
      alert("dig/an input 8 low is not a number")
      return
    };
  };
  if(this.state.digan8Low === ''){
    this.state.digan8Low = 0
  }

  if(this.state.an1High !== ''){
    this.state.an1High = parseFloat(this.state.an1High)
    if(isNaN(this.state.an1High)){
      this.state.an1High = ''
      alert("analog input 1 high is not a number")
      return
    };
  };
  if(this.state.an1High === ''){
    this.state.an1High = 0
  }

  if(this.state.an2High !== ''){
    this.state.an2High = parseFloat(this.state.an2High)
    if(isNaN(this.state.an2High)){
      this.state.an2High = ''
      alert("analog input 2 high is not a number")
      return
    };
  };
  if(this.state.an2High === ''){
    this.state.an2High = 0
  }

  if(this.state.an3High !== ''){
    this.state.an3High = parseFloat(this.state.an3High)
    if(isNaN(this.state.an3High)){
      this.state.an3High = ''
      alert("analog input 3 high is not a number")
      return
    };
  };
  if(this.state.an3High === ''){
    this.state.an3High = 0
  }

  if(this.state.an4High !== ''){
    this.state.an4High = parseFloat(this.state.an4High)
    if(isNaN(this.state.an4High)){
      this.state.an4High = ''
      alert("analog input 4 high is not a number")
      return
    };
  };
  if(this.state.an4High === ''){
    this.state.an4High = 0
  }

  if(this.state.digan5High !== ''){
    this.state.digan5High = parseFloat(this.state.digan5High)
    if(isNaN(this.state.digan5High)){
      this.state.digan5High = ''
      alert("dig/an input 5 high is not a number")
      return
    };
  };
  if(this.state.digan5High === ''){
    this.state.digan5High = 0
  }

  if(this.state.digan6High !== ''){
    this.state.digan6High = parseFloat(this.state.digan6High)
    if(isNaN(this.state.digan6High)){
      this.state.digan6High = ''
      alert("dig/an input 6 high is not a number")
      return
    };
  };
  if(this.state.digan6High === ''){
    this.state.digan6High = 0
  }

  if(this.state.digan7High !== ''){
    this.state.digan7High = parseFloat(this.state.digan7High)
    if(isNaN(this.state.digan7High)){
      this.state.digan7High = ''
      alert("dig/an input 7 high is not a number")
      return
    };
  };
  if(this.state.digan7High === ''){
    this.state.digan7High = 0
  }

  if(this.state.digan8High !== ''){
    this.state.digan8High = parseFloat(this.state.digan8High)
    if(isNaN(this.state.digan8High)){
      this.state.digan8High = ''
      alert("dig/an input 8 high is not a number")
      return
    };
  };
  if(this.state.digan8High === ''){
    this.state.digan8High = 0
  }

  if(this.state.an1Round !== ''){
    this.state.an1Round = parseFloat(this.state.an1Round)
    if(isNaN(this.state.an1Round)){
      this.state.an1Round = ''
      alert("analog input 1 round is not a number")
      return
    };
  };
  if(this.state.an1Round === ''){
    this.state.an1Round = 0
  }

  if(this.state.an2Round !== ''){
    this.state.an2Round = parseFloat(this.state.an2Round)
    if(isNaN(this.state.an2Round)){
      this.state.an2Round = ''
      alert("analog input 2 round is not a number")
      return
    };
  };
  if(this.state.an2Round === ''){
    this.state.an2Round = 0
  }

  if(this.state.an3Round !== ''){
    this.state.an3Round = parseFloat(this.state.an3Round)
    if(isNaN(this.state.an3Round)){
      this.state.an3Round = ''
      alert("analog input 3 round is not a number")
      return
    };
  };
  if(this.state.an3Round === ''){
    this.state.an3Round = ''
  }

  if(this.state.an4Round !== ''){
    this.state.an4Round = parseFloat(this.state.an4Round)
    if(isNaN(this.state.an4Round)){
      this.state.an4Round = ''
      alert("analog input 4 round is not a number")
      return
    };
  };
  if(this.state.an4Round === ''){
    this.state.an4Round = 0
  }

  if(this.state.digan5Round !== ''){
    this.state.digan5Round = parseFloat(this.state.digan5Round)
    if(isNaN(this.state.digan5Round)){
      this.state.digan5Round = ''
      alert("dig/an input 5 round is not a number")
      return
    };
  };
  if(this.state.digan5Round === ''){
    this.state.digan5Round = 0
  }

  if(this.state.digan6Round !== ''){
    this.state.digan6Round = parseFloat(this.state.digan6Round)
    if(isNaN(this.state.digan6Round)){
      this.state.digan6Round = ''
      alert("dig/an input 6 round is not a number")
      return
    };
  };
  if(this.state.digan6Round === ''){
    this.state.digan6Round = 0
  }

  if(this.state.digan7Round !== ''){
    this.state.digan7Round = parseFloat(this.state.digan7Round)
    if(isNaN(this.state.digan7Round)){
      this.state.digan7Round = ''
      alert("dig/an input 7 round is not a number")
      return
    };
  };
  if(this.state.digan7Round === ''){
    this.state.digan7Round = 0
  }

  if(this.state.digan8Round !== ''){
    this.state.digan8Round = parseFloat(this.state.digan8Round)
    if(isNaN(this.state.digan8Round)){
      this.state.digan8Round = ''
      alert("dig/an input 8 round is not a number")
      return
    };
  };
  if(this.state.digan8Round === ''){
    this.state.digan8Round = 0
  }

  if(this.state.an1ScaleLow !== ''){
    this.state.an1ScaleLow = parseFloat(this.state.an1ScaleLow)
    if(isNaN(this.state.an1ScaleLow)){
      this.state.an1ScaleLow = ''
      alert("analog input 1 scale low is not a number")
      return
    };
  };
  if(this.state.an1ScaleLow === ''){
    this.state.an1ScaleLow = 0
  }

  if(this.state.an2ScaleLow !== ''){
    this.state.an2ScaleLow = parseFloat(this.state.an2ScaleLow)
    if(isNaN(this.state.an2ScaleLow)){
      this.state.an2ScaleLow = ''
      alert("analog input 2 scale low is not a number")
      return
    };
  };
  if(this.state.an2ScaleLow === ''){
    this.state.an2ScaleLow = 0
  }

  if(this.state.an3ScaleLow !== ''){
    this.state.an3ScaleLow = parseFloat(this.state.an3ScaleLow)
    if(isNaN(this.state.an3ScaleLow)){
      this.state.an3ScaleLow = ''
      alert("analog input 3 scale low is not a number")
      return
    };
  };
  if(this.state.an3ScaleLow === ''){
    this.state.an3ScaleLow = 0
  }

  if(this.state.an4ScaleLow !== ''){
    this.state.an4ScaleLow = parseFloat(this.state.an4ScaleLow)
    if(isNaN(this.state.an4ScaleLow)){
      this.state.an4ScaleLow = ''
      alert("analog input 4 scale low is not a number")
      return
    };
  };
  if(this.state.an4ScaleLow === ''){
    this.state.an4ScaleLow = 0
  }

  if(this.state.digan5ScaleLow !== ''){
    this.state.digan5ScaleLow = parseFloat(this.state.digan5ScaleLow)
    if(isNaN(this.state.digan5ScaleLow)){
      this.state.digan5ScaleLow = ''
      alert("dig/an input 5 scale low is not a number")
      return
    };
  };
  if(this.state.digan5ScaleLow === ''){
    this.state.digan5ScaleLow = 0
  }

  if(this.state.digan6ScaleLow !== ''){
    this.state.digan6ScaleLow = parseFloat(this.state.digan6ScaleLow)
    if(isNaN(this.state.digan6ScaleLow)){
      this.state.digan6ScaleLow = ''
      alert("dig/an input 6 scale low is not a number")
      return
    };
  };
  if(this.state.digan6ScaleLow === ''){
    this.state.digan6ScaleLow = 0
  }

  if(this.state.digan7ScaleLow !== ''){
    this.state.digan7ScaleLow = parseFloat(this.state.digan7ScaleLow)
    if(isNaN(this.state.digan7ScaleLow)){
      this.state.digan7ScaleLow = ''
      alert("dig/an input 7 scale low is not a number")
      return
    };
  };
  if(this.state.digan7ScaleLow === ''){
    this.state.digan7ScaleLow = 0
  }

  if(this.state.digan8ScaleLow !== ''){
    this.state.digan8ScaleLow = parseFloat(this.state.digan8ScaleLow)
    if(isNaN(this.state.digan8ScaleLow)){
      this.state.digan8ScaleLow = ''
      alert("dig/an input 8 scale low is not a number")
      return
    };
  };
  if(this.state.digan8ScaleLow === ''){
    this.state.digan8ScaleLow = 0
  }



  if(this.state.an1ScaleHigh !== ''){
    this.state.an1ScaleHigh = parseFloat(this.state.an1ScaleHigh)
    if(isNaN(this.state.an1ScaleHigh)){
      this.state.an1ScaleHigh = ''
      alert("analog input 1 scale high is not a number")
      return
    };
  };
  if(this.state.an1ScaleHigh === ''){
    this.state.an1ScaleHigh = 0
  }

  if(this.state.an2ScaleHigh !== ''){
    this.state.an2ScaleHigh = parseFloat(this.state.an2ScaleHigh)
    if(isNaN(this.state.an2ScaleHigh)){
      this.state.an2ScaleHigh = ''
      alert("analog input 2 scale high is not a number")
      return
    };
  };
  if(this.state.an2ScaleHigh === ''){
    this.state.an2ScaleHigh = 0
  }

  if(this.state.an3ScaleHigh !== ''){
    this.state.an3ScaleHigh = parseFloat(this.state.an3ScaleHigh)
    if(isNaN(this.state.an3ScaleHigh)){
      this.state.an3ScaleHigh = ''
      alert("analog input 3 scale high is not a number")
      return
    };
  };
  if(this.state.an3ScaleHigh === ''){
    this.state.an3ScaleHigh = 0
  }

  if(this.state.an4ScaleHigh !== ''){
    this.state.an4ScaleHigh = parseFloat(this.state.an4ScaleHigh)
    if(isNaN(this.state.an4ScaleHigh)){
      this.state.an4ScaleHigh = ''
      alert("analog input 4 scale high is not a number")
      return
    };
  };
  if(this.state.an4ScaleHigh === ''){
    this.state.an4ScaleHigh = 0
  }

  if(this.state.digan5ScaleHigh !== ''){
    this.state.digan5ScaleHigh = parseFloat(this.state.digan5ScaleHigh)
    if(isNaN(this.state.digan5ScaleHigh)){
      this.state.digan5ScaleHigh = ''
      alert("dig/an input 5 scale high is not a number")
      return
    };
  };
  if(this.state.digan5ScaleHigh === ''){
    this.state.digan5ScaleHigh = 0
  }

  if(this.state.digan6ScaleHigh !== ''){
    this.state.digan6ScaleHigh = parseFloat(this.state.digan6ScaleHigh)
    if(isNaN(this.state.digan6ScaleHigh)){
      this.state.digan6ScaleHigh = ''
      alert("dig/an input 6 scale high is not a number")
      return
    };
  };
  if(this.state.digan6ScaleHigh === ''){
    this.state.digan6ScaleHigh = 0
  }

  if(this.state.digan7ScaleHigh !== ''){
    this.state.digan7ScaleHigh = parseFloat(this.state.digan7ScaleHigh)
    if(isNaN(this.state.digan7ScaleHigh)){
      this.state.digan7ScaleHigh = ''
      alert("dig/an input 7 scale high is not a number")
      return
    };
  };
  if(this.state.digan7ScaleHigh === ''){
    this.state.digan7ScaleHigh = 0
  }

  if(this.state.digan8ScaleHigh !== ''){
    this.state.digan8ScaleHigh = parseFloat(this.state.digan8ScaleHigh)
    if(isNaN(this.state.digan8ScaleHigh)){
      this.state.digan8ScaleHigh = ''
      alert("dig/an input 8 scale high is not a number")
      return
    };
  };
  if(this.state.digan8ScaleHigh === ''){
    this.state.digan8ScaleHigh = 0
  }

  if(this.state.dig5Value !== ''){
    this.state.dig5Value = parseFloat(this.state.dig5Value)
    if(isNaN(this.state.dig5Value)){
      this.state.dig5value = ''
      alert("dig/an input 5 closure value is not a number")
      return
    };
  };
  if(this.state.dig5Value === ''){
    this.state.dig5Value = 0
  }

  if(this.state.dig6Value !== ''){
    this.state.dig6Value = parseFloat(this.state.dig6Value)
    if(isNaN(this.state.dig6Value)){
      this.state.dig6value = ''
      alert("dig/an input 6 closure value is not a number")
      return
    };
  };
  if(this.state.dig6Value === ''){
    this.state.dig6Value = 0
  }

  if(this.state.dig7Value !== ''){
    this.state.dig7Value = parseFloat(this.state.dig7Value)
    if(isNaN(this.state.dig7Value)){
      this.state.dig7value = ''
      alert("dig/an input 7 closure value is not a number")
      return
    };
  };
  if(this.state.dig7Value === ''){
    this.state.dig7Value = 0
  }

  if(this.state.dig8Value !== ''){
    this.state.dig8Value = parseFloat(this.state.dig8Value)
    if(isNaN(this.state.dig8Value)){
      this.state.dig8value = ''
      alert("dig/an input 8 closure value is not a number")
      return
    };
  };
  if(this.state.dig8Value === ''){
    this.state.dig8Value = 0
  }

  if(this.state.dig5Closed !== ''){
    this.state.dig5Closed = parseFloat(this.state.dig5Closed)
    if(isNaN(this.state.dig5Closed)){
      this.state.dig5Closed = ''
      alert("dig/an input 5 closed is not a number")
      return
    };
  };
  if(this.state.dig5Closed === ''){
    this.state.dig5Closed = 0
  }

  if(this.state.dig6Closed !== ''){
    this.state.dig6Closed = parseFloat(this.state.dig6Closed)
    if(isNaN(this.state.dig6Closed)){
      this.state.dig6Closed = ''
      alert("dig/an input 6 closed is not a number")
      return
    };
  };
  if(this.state.dig6Closed === ''){
    this.state.dig6Closed = 0
  }

  if(this.state.dig7Closed !== ''){
    this.state.dig7Closed = parseFloat(this.state.dig7Closed)
    if(isNaN(this.state.dig7Closed)){
      this.state.dig7Closed = ''
      alert("dig/an input 7 closed is not a number")
      return
    };
  };
  if(this.state.dig7Closed === ''){
    this.state.dig7Closed = 0
  }

  if(this.state.dig8Closed !== ''){
    this.state.dig8Closed = parseFloat(this.state.dig8Closed)
    if(isNaN(this.state.dig8Closed)){
      this.state.dig8Closed = ''
      alert("dig/an input 8 closed is not a number")
      return
    };
  };
  if(this.state.dig8Closed === ''){
    this.state.dig8Closed = 0
  }

  if(this.state.dig5Open !== ''){
    this.state.dig5Open = parseFloat(this.state.dig5Open)
    if(isNaN(this.state.dig5Open)){
      this.state.dig5Open = ''
      alert("dig/an input 5 open is not a number")
      return
    };
  };
  if(this.state.dig5Open === ''){
    this.state.dig5Open = 0
  }

  if(this.state.dig6Open !== ''){
    this.state.dig6Open = parseFloat(this.state.dig6Open)
    if(isNaN(this.state.dig6Open)){
      this.state.dig6Open = ''
      alert("dig/an input 6 open is not a number")
      return
    };
  };
  if(this.state.dig6Open === ''){
    this.state.dig6Open = 0
  }

  if(this.state.dig7Open !== ''){
    this.state.dig7Open = parseFloat(this.state.dig7Open)
    if(isNaN(this.state.dig7Open)){
      this.state.dig7Open = ''
      alert("dig/an input 7 open is not a number")
      return
    };
  };
  if(this.state.dig7Open === ''){
    this.state.dig7Open = 0
  }

  if(this.state.dig8Open !== ''){
    this.state.dig8Open = parseFloat(this.state.dig8Open)
    if(isNaN(this.state.dig8Open)){
      this.state.dig8Open = ''
      alert("dig/an input 8 open is not a number")
      return
    };
  };
  if(this.state.dig8Open === ''){
    this.state.dig8Open = 0
  }

  if(this.state.an1AlarmLow !== ''){
    this.state.an1AlarmLow = parseFloat(this.state.an1AlarmLow)
    if(isNaN(this.state.an1AlarmLow)){
      this.state.an1AlarmLow = ''
      alert("analog input 1 alarm low is not a number")
      return
    };
  };
  if(this.state.an1AlarmLow === ''){
    this.state.an1AlarmLow = 0
  }

  if(this.state.an2AlarmLow !== ''){
    this.state.an2AlarmLow = parseFloat(this.state.an2AlarmLow)
    if(isNaN(this.state.an2AlarmLow)){
      this.state.an2AlarmLow = ''
      alert("analog input 2 alarm low is not a number")
      return
    };
  };
  if(this.state.an2AlarmLow === ''){
    this.state.an2AlarmLow = 0
  }

  if(this.state.an3AlarmLow !== ''){
    this.state.an3AlarmLow = parseFloat(this.state.an3AlarmLow)
    if(isNaN(this.state.an3AlarmLow)){
      this.state.an3AlarmLow = ''
      alert("analog input 3 alarm low is not a number")
      return
    };
  };
  if(this.state.an3AlarmLow === ''){
    this.state.an3AlarmLow = 0
  }

  if(this.state.an4AlarmLow !== ''){
    this.state.an4AlarmLow = parseFloat(this.state.an4AlarmLow)
    if(isNaN(this.state.an4AlarmLow)){
      this.state.an4AlarmLow = ''
      alert("analog input 4 alarm low is not a number")
      return
    };
  };
  if(this.state.an4AlarmLow === ''){
    this.state.an4AlarmLow = 0
  }

  if(this.state.dig5AlarmLow !== ''){
    this.state.dig5AlarmLow = parseFloat(this.state.dig5AlarmLow)
    if(isNaN(this.state.dig5AlarmLow)){
      this.state.dig5AlarmLow = ''
      alert("dig/an input 5 alarm low is not a number")
      return
    };
  };
  if(this.state.dig5AlarmLow === ''){
    this.state.dig5AlarmLow = 0
  }

  if(this.state.dig6AlarmLow !== ''){
    this.state.dig6AlarmLow = parseFloat(this.state.dig6AlarmLow)
    if(isNaN(this.state.dig6AlarmLow)){
      this.state.dig6AlarmLow = ''
      alert("dig/an input 6 alarm low is not a number")
      return
    };
  };
  if(this.state.dig6AlarmLow === ''){
    this.state.dig6AlarmLow = 0
  }

  if(this.state.dig7AlarmLow !== ''){
    this.state.dig7AlarmLow = parseFloat(this.state.dig7AlarmLow)
    if(isNaN(this.state.dig7AlarmLow)){
      this.state.dig7AlarmLow = ''
      alert("dig/an input 7 alarm low is not a number")
      return
    };
  };
  if(this.state.dig7AlarmLow === ""){
    this.state.dig7AlarmLow = 0
  }

  if(this.state.dig8AlarmLow !== ''){
    this.state.dig8AlarmLow = parseFloat(this.state.dig8AlarmLow)
    if(isNaN(this.state.dig8AlarmLow)){
      this.state.dig8AlarmLow = ''
      alert("dig/an input 8 alarm low is not a number")
      return
    };
  };
  if(this.state.dig8AlarmLow === ''){
    this.state.dig8AlarmLow = 0
  }

  if(this.state.an1AlarmHigh !== ''){
    this.state.an1AlarmHigh = parseFloat(this.state.an1AlarmHigh)
    if(isNaN(this.state.an1AlarmHigh)){
      this.state.an1AlarmHigh = ''
      alert("analog input 1 alarm high is not a number")
      return
    };
  };
  if(this.state.an1AlarmHigh === ''){
    this.state.an1AlarmHigh = 0
  }

  if(this.state.an2AlarmHigh !== ''){
    this.state.an2AlarmHigh = parseFloat(this.state.an2AlarmHigh)
    if(isNaN(this.state.an2AlarmHigh)){
      this.state.an2AlarmHigh = ''
      alert("analog input 2 alarm high is not a number")
      return
    };
  };
  if(this.state.an2AlarmHigh === ''){
    this.state.an2AlarmHigh = 0
  }

  if(this.state.an3AlarmHigh !== ''){
    this.state.an3AlarmHigh = parseFloat(this.state.an3AlarmHigh)
    if(isNaN(this.state.an3AlarmHigh)){
      this.state.an3AlarmHigh = ''
      alert("analog input 3 alarm high is not a number")
      return
    };
  };
  if(this.state.an3AlarmHigh === ''){
    this.state.an3AlarmHigh = 0
  }

  if(this.state.an4AlarmHigh !== ''){
    this.state.an4AlarmHigh = parseFloat(this.state.an4AlarmHigh)
    if(isNaN(this.state.an4AlarmHigh)){
      this.state.an4AlarmHigh = ''
      alert("analog input 4 alarm high is not a number")
      return
    };
  };
  if(this.state.an4AlarmHigh === ''){
    this.state.an4AlarmHigh = 0
  }

  if(this.state.dig5AlarmHigh !== ''){
    this.state.dig5AlarmHigh = parseFloat(this.state.dig5AlarmHigh)
    if(isNaN(this.state.dig5AlarmHigh)){
      this.state.dig5AlarmHigh = ''
      alert("dig/an input 5 alarm high is not a number")
      return
    };
  };
  if(this.state.dig5AlarmHigh === ''){
    this.state.dig5AlarmHigh = 0
  }

  if(this.state.dig6AlarmHigh !== ''){
    this.state.dig6AlarmHigh = parseFloat(this.state.dig6AlarmHigh)
    if(isNaN(this.state.dig6AlarmHigh)){
      this.state.dig6AlarmHigh = ''
      alert("dig/an input 6 alarm high is not a number")
      return
    };
  };
  if(this.state.dig6AlarmHigh === ''){
    this.state.dig6AlarmHigh = 0
  }

  if(this.state.dig7AlarmHigh !== ''){
    this.state.dig7AlarmHigh = parseFloat(this.state.dig7AlarmHigh)
    if(isNaN(this.state.dig7AlarmHigh)){
      this.state.dig7AlarmHigh = ''
      alert("dig/an input 7 alarm high is not a number")
      return
    };
  };
  if(this.state.dig7AlarmHigh === ''){
    this.state.dig7AlarmHigh = 0
  }

  if(this.state.dig8AlarmHigh !== ''){
    this.state.dig8AlarmHigh = parseFloat(this.state.dig8AlarmHigh)
    if(isNaN(this.state.dig8AlarmHigh)){
      this.state.dig8AlarmHigh = ''
      alert("dig/an input 8 alarm high is not a number")
      return
    };
  };
  if(this.state.dig8AlarmHigh === ''){
    this.state.dig8AlarmHigh = 0
  }

  if(this.state.dig5BounceHour !== ''){
    this.state.dig5BounceHour = parseFloat(this.state.dig5BounceHour)
    if(isNaN(this.state.dig5BounceHour)){
      this.state.dig5BounceHour = ''
      alert("dig/an input 5 bounce hour is not a number")
      return
    };
  };
  if(this.state.dig5BounceHour === ''){
    this.state.dig5BounceHour = 0
  }

  if(this.state.dig6BounceHour !== ''){
    this.state.dig6BounceHour = parseFloat(this.state.dig6BounceHour)
    if(isNaN(this.state.dig6BounceHour)){
      this.state.dig6BounceHour = ''
      alert("dig/an input 6 bounce hour is not a number")
      return
    };
  };
  if(this.state.dig6BounceHour === ''){
    this.state.dig6BounceHour = 0
  }

  if(this.state.dig7BounceHour !== ''){
    this.state.dig7BounceHour = parseFloat(this.state.dig7BounceHour)
    if(isNaN(this.state.dig7BounceHour)){
      this.state.dig7BounceHour = ''
      alert("dig/an input 7 bounce hour is not a number")
      return
    };
  };
  if(this.state.dig7BounceHour === ''){
    this.state.dig7BounceHour = 0
  }

  if(this.state.dig8BounceHour !== ''){
    this.state.dig8BounceHour = parseFloat(this.state.dig8BounceHour)
    if(isNaN(this.state.dig8BounceHour)){
      this.state.dig8BounceHour = ''
      alert("dig/an input 8 bounce hour is not a number")
      return
    };
  };
  if(this.state.dig8BounceHour === ''){
    this.state.dig8BounceHour = 0
  }

  if(this.state.dig5BounceMin !== ''){
    this.state.dig5BounceMin = parseFloat(this.state.dig5BounceMin)
    if(isNaN(this.state.dig5BounceMin)){
      this.state.dig5BounceMin = ''
      alert("dig/an input 5 bounce min is not a number")
      return
    };
  };
  if(this.state.dig5BounceMin === ''){
    this.state.dig5BounceMin = 0
  }

  if(this.state.dig6BounceMin !== ''){
    this.state.dig6BounceMin = parseFloat(this.state.dig6BounceMin)
    if(isNaN(this.state.dig6BounceMin)){
      this.state.dig6BounceMin = ''
      alert("dig/an input 6 bounce min is not a number")
      return
    };
  };
  if(this.state.dig6BounceMin === ''){
    this.state.dig6BounceMin = 0
  }

  if(this.state.dig7BounceMin !== ''){
    this.state.dig7BounceMin = parseFloat(this.state.dig7BounceMin)
    if(isNaN(this.state.dig7BounceMin)){
      this.state.dig7BounceMin = ''
      alert("dig/an input 7 bounce min is not a number")
      return
    };
  };
  if(this.state.dig7BounceMin === ''){
    this.state.dig7BounceMin = 0
  }

  if(this.state.dig8BounceMin !== ''){
    this.state.dig8BounceMin = parseFloat(this.state.dig8BounceMin)
    if(isNaN(this.state.dig8BounceMin)){
      this.state.dig8BounceMin = ''
      alert("dig/an input 8 bounce min is not a number")
      return
    };
  };
  if(this.state.dig8BounceMin === ''){
    this.state.dig8BounceMin = 0
  }

  if(this.state.dig5BounceSec !== ''){
    this.state.dig5BounceSec = parseFloat(this.state.dig5BounceSec)
    if(isNaN(this.state.dig5BounceSec)){
      this.state.dig5BounceSec = ''
      alert("dig/an input 5 bounce sec is not a number")
      return
    };
  };
  if(this.state.dig5BounceSec === ''){
    this.state.dig5BounceSec = 0
  }

  if(this.state.dig6BounceSec !== ''){
    this.state.dig6BounceSec = parseFloat(this.state.dig6BounceSec)
    if(isNaN(this.state.dig6BounceSec)){
      this.state.dig6BounceSec = ''
      alert("dig/an input 6 bounce sec is not a number")
      return
    };
  };
  if(this.state.dig6BounceSec === ''){
    this.state.dig6BounceSec = 0
  }

  if(this.state.dig7BounceSec !== ''){
    this.state.dig7BounceSec = parseFloat(this.state.dig7BounceSec)
    if(isNaN(this.state.dig7BounceSec)){
      this.state.dig7BounceSec = ''
      alert("dig/an input 7 bounce sec is not a number")
      return
    };
  };
  if(this.state.dig7BounceSec === ''){
    this.state.dig7BounceSec = 0
  }

  if(this.state.dig8BounceSec !== ''){
    this.state.dig8BounceSec = parseFloat(this.state.dig8BounceSec)
    if(isNaN(this.state.dig8BounceSec)){
      this.state.dig8BounceSec = ''
      alert("dig/an input 8 bounce sec is not a number")
      return
    };
  };
  if(this.state.dig8BounceSec === ''){
    this.state.dig8BounceSec = 0
  }

  if(this.state.an1AlarmHour !== ''){
    this.state.an1AlarmHour = parseFloat(this.state.an1AlarmHour)
    if(isNaN(this.state.an1AlarmHour)){
      this.state.an1AlarmHour = ''
      alert("analog input 1 alarm hour is not a number")
      return
    };
  };
  if(this.state.an1AlarmHour === ''){
    this.state.an1AlarmHour = 0
  }

  if(this.state.an2AlarmHour !== ''){
    this.state.an2AlarmHour = parseFloat(this.state.an2AlarmHour)
    if(isNaN(this.state.an2AlarmHour)){
      this.state.an2AlarmHour = ''
      alert("analog input 2 alarm hour is not a number")
      return
    };
  };
  if(this.state.an2AlarmHour === ''){
    this.state.an2AlarmHour = 0
  }

  if(this.state.an3AlarmHour !== ''){
    this.state.an3AlarmHour = parseFloat(this.state.an3AlarmHour)
    if(isNaN(this.state.an3AlarmHour)){
      this.state.an3AlarmHour = ''
      alert("analog input 3 alarm hour is not a number")
      return
    };
  };
  if(this.state.an3AlarmHour === ''){
    this.state.an3AlarmHour = 0
  }

  if(this.state.an4AlarmHour !== ''){
    this.state.an4AlarmHour = parseFloat(this.state.an4AlarmHour)
    if(isNaN(this.state.an4AlarmHour)){
      this.state.an4AlarmHour = ''
      alert("analog input 4 alarm hour is not a number")
      return
    };
  };
  if(this.state.an4AlarmHour === ''){
    this.state.an4AlarmHour = 0
  }

  if(this.state.dig5AlarmHour !== ''){
    this.state.dig5AlarmHour = parseFloat(this.state.dig5AlarmHour)
    if(isNaN(this.state.dig5AlarmHour)){
      this.state.dig5AlarmHour = ''
      alert("dig/an input 5 alarm hour is not a number")
      return
    };
  };
  if(this.state.dig5AlarmHour === ''){
    this.state.dig5AlarmHour = 0
  }

  if(this.state.dig6AlarmHour !== ''){
    this.state.dig6AlarmHour = parseFloat(this.state.dig6AlarmHour)
    if(isNaN(this.state.dig6AlarmHour)){
      this.state.dig6AlarmHour = ''
      alert("dig/an input 6 alarm hour is not a number")
      return
    };
  };
  if(this.state.dig6AlarmHour === ''){
    this.state.dig6AlarmHour = 0
  }

  if(this.state.dig7AlarmHour !== ''){
    this.state.dig7AlarmHour = parseFloat(this.state.dig7AlarmHour)
    if(isNaN(this.state.dig7AlarmHour)){
      this.state.dig7AlarmHour = ''
      alert("dig/an input 7 alarm hour is not a number")
      return
    };
  };
  if(this.state.dig7AlarmHour === ''){
    this.state.dig7AlarmHour = 0
  }

  if(this.state.dig8AlarmHour !== ''){
    this.state.dig8AlarmHour = parseFloat(this.state.dig8AlarmHour)
    if(isNaN(this.state.dig8AlarmHour)){
      this.state.dig8AlarmHour = ''
      alert("dig/an input 8 alarm hour is not a number")
      return
    };
  };
  if(this.state.dig8AlarmHour === ''){
    this.state.dig8AlarmHour = 0
  }

  if(this.state.an1AlarmMin !== ''){
    this.state.an1AlarmMin = parseFloat(this.state.an1AlarmMin)
    if(isNaN(this.state.an1AlarmMin)){
      this.state.an1AlarmMin = ''
      alert("analog input 1 alarm min is not a number")
      return
    };
  };
  if(this.state.an1AlarmMin === ''){
    this.state.an1AlarmMin = 0
  }

  if(this.state.an2AlarmMin !== ''){
    this.state.an2AlarmMin = parseFloat(this.state.an2AlarmMin)
    if(isNaN(this.state.an2AlarmMin)){
      this.state.an2AlarmMin = ''
      alert("analog input 2 alarm min is not a number")
      return
    };
  };
  if(this.state.an2AlarmMin === ''){
    this.state.an2AlarmMin = 0
  }

  if(this.state.an3AlarmMin !== ''){
    this.state.an3larmMin = parseFloat(this.state.an3AlarmMin)
    if(isNaN(this.state.an3AlarmMin)){
      this.state.an3AlarmMin = ''
      alert("analog input 3 alarm min is not a number")
      return
    };
  };
  if(this.state.an3AlarmMin === ''){
    this.state.an3AlarmMin = 0
  }

  if(this.state.an4AlarmMin !== ''){
    this.state.an4AlarmMin = parseFloat(this.state.an4AlarmMin)
    if(isNaN(this.state.an4AlarmMin)){
      this.state.an4AlarmMin = ''
      alert("analog input 4 alarm min is not a number")
      return
    };
  };
  if(this.state.an4AlarmMin === ''){
    this.state.an4AlarmMin = 0
  }

  if(this.state.dig5AlarmMin !== ''){
    this.state.dig5AlarmMin = parseFloat(this.state.dig5AlarmMin)
    if(isNaN(this.state.dig5AlarmMin)){
      this.state.dig5AlarmMin = ''
      alert("dig/an input 5 alarm min is not a number")
      return
    };
  };
  if(this.state.dig5AlarmMin === ''){
    this.state.dig5AlarmMin = 0
  }

  if(this.state.dig6AlarmMin !== ''){
    this.state.dig6AlarmMin = parseFloat(this.state.dig6AlarmMin)
    if(isNaN(this.state.dig6AlarmMin)){
      this.state.dig6AlarmMin = ''
      alert("dig/an input 6 alarm min is not a number")
      return
    };
  };
  if(this.state.dig6AlarmMin === ''){
    this.state.dig6AlarmMin = 0
  }

  if(this.state.dig7AlarmMin !== ''){
    this.state.dig7AlarmMin = parseFloat(this.state.dig7AlarmMin)
    if(isNaN(this.state.dig7AlarmMin)){
      this.state.dig7AlarmMin = ''
      alert("dig/an input 7 alarm min is not a number")
      return
    };
  };
  if(this.state.dig7AlarmMin === ''){
    this.state.dig7AlarmMin = 0
  }

  if(this.state.dig8AlarmMin !== ''){
    this.state.dig8AlarmMin = parseFloat(this.state.dig8AlarmMin)
    if(isNaN(this.state.dig8AlarmMin)){
      this.state.dig8AlarmMin = ''
      alert("dig/an input 8 alarm min is not a number")
      return
    };
  };

  if(this.state.dig8AlarmMin === ''){
    this.state.dig8AlarmMin = 0
  }

  if(this.state.an1High < this.state.an1Low){
    alert("analog 1 high volt must be greater than the low volt")
    return
  }

  if(this.state.an2High < this.state.an2Low){
    alert("analog 2 high volt must be greater than the low volt")
    return
  }

  if(this.state.an3High < this.state.an3Low){
    alert("analog 3 high volt must be greater than the low volt")
    return
  }

  if(this.state.an4High < this.state.an4Low){
    alert("analog 4 high volt must be greater than the low volt")
    return
  }

  if(this.state.digan5High < this.state.digan5Low){
    alert("dig/an 5 high volt must be greater than the low volt")
    return
  }

  if(this.state.digan6High < this.state.digan6Low){
    alert("dig/an 6 high volt must be greater than the low volt")
    return
  }

  if(this.state.digan7High < this.state.digan7Low){
    alert("dig/an 7 high volt must be greater than the low volt")
    return
  }

  if(this.state.digan8High < this.state.digan8Low){
    alert("dig/an 8 high volt must be greater than the low volt")
    return
  }
  if(this.state.an1ScaleHigh < this.state.an1ScaleLow){
    alert("analog 1 high scale must be greater than the low scale")
    return
  }
  if(this.state.an2ScaleHigh < this.state.an2ScaleLow){
    alert("analog 2 high scale must be greater than the low scale")
    return
  }
  if(this.state.an3ScaleHigh < this.state.an3ScaleLow){
    alert("analog 3 high scale must be greater than the low scale")
    return
  }
  if(this.state.an4ScaleHigh < this.state.an4ScaleLow){
    alert("analog 4 high scale must be greater than the low scale")
    return
  }

  if(this.state.digan5ScaleHigh < this.state.digan5ScaleLow){
    alert("dig/an 5 high scale must be greater than the low scale")
    return
  }
  if(this.state.digan6ScaleHigh < this.state.digan6ScaleLow){
    alert("dig/an 6 high scale must be greater than the low scale")
    return
  }
  if(this.state.digan7ScaleHigh < this.state.digan7ScaleLow){
    alert("dig/an 7 high scale must be greater than the low scale")
    return
  }
  if(this.state.digan8ScaleHigh < this.state.digan8ScaleLow){
    alert("dig/an 8 high scale must be greater than the low scale")
    return
  }

  if(this.state.an1Round < 0){
    alert("analog 1 round must be a positive number")
    return
  }

  if(this.state.an2Round < 0){
    alert("analog 2 round must be a positive number")
    return
  }

  if(this.state.an3Round < 0){
    alert("analog 3 round must be a positive number")
    return
  }

  if(this.state.an3Round < 0){
    alert("analog 3 round must be a positive number")
    return
  }

  if(this.state.digan5Round < 0){
    alert("dig/an 5 round must be a positive number")
    return
  }

  if(this.state.digan6Round < 0){
    alert("dig/an 6 round must be a positive number")
    return
  }

  if(this.state.digan7Round < 0){
    alert("dig/an 7 round must be a positive number")
    return
  }

  if(this.state.digan8Round < 0){
    alert("dig/an 8 round must be a positive number")
    return
  }

  if(this.state.an1 === true && this.state.an1Name === ''){
    alert("analog 1 must have a name")
    return
  }

  if(this.state.an2 === true && this.state.an2Name === ''){
    alert("analog 2 must have a name")
    return
  }

  if(this.state.an3 === true && this.state.an3Name === ''){
    alert("analog 3 must have a name")
    return
  }

  if(this.state.an4 === true && this.state.an4Name === ''){
    alert("analog 4 must have a name")
    return
  }

  if(this.state.dig5 === true && this.state.dig5Name === ''){
    alert("dig/an 5 must have a name")
    return
  }

  if(this.state.dig5 === true && this.state.dig5Name === ''){
    alert("dig/an 5 must have a name")
    return
  }

  if(this.state.dig6 === true && this.state.dig6Name === ''){
    alert("dig/an 6 must have a name")
    return
  }

  if(this.state.dig7 === true && this.state.dig7Name === ''){
    alert("dig/an 7 must have a name")
    return
  }

  if(this.state.dig8 === true && this.state.dig8Name === ''){
    alert("dig/an 8 must have a name")
    return
  }

  if(this.state.alarmOn === true && this.state.deviceAlertName === ''){
    this.setState({
        deviceAlertName: this.local
      }
    )
  }

  //eslint-disable-next-line
  let regex = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

  if(this.state.an1 === true && this.state.an1Name !== ''){    
    if(regex.test(this.state.an1Name)){
      alert("analog 1 name can not contain special character")
      return
    }
  }
  if(this.state.an2 === true && this.state.an2Name !== ''){    
    if(regex.test(this.state.an2Name)){
      alert("analog 2 name can not contain special character")
      return
    }
  }
  if(this.state.an3 === true && this.state.an3Name !== ''){    
    if(regex.test(this.state.an3Name)){
      alert("analog 3 name can not contain special character")
      return
    }
  }
  if(this.state.an4 === true && this.state.an4Name !== ''){    
    if(regex.test(this.state.an4Name)){
      alert("analog 4 name can not contain special character")
      return
    }
  }
  if(this.state.dig5 === true && this.state.dig5Name !== ''){    
    if(regex.test(this.state.dig5Name)){
      alert("dig/an 5 name can not contain special character")
      return
    }
  }
  if(this.state.dig6 === true && this.state.dig6Name !== ''){    
    if(regex.test(this.state.dig6Name)){
      alert("dig/an 6 name can not contain special character")
      return
    }
  }
  if(this.state.dig7 === true && this.state.dig7Name !== ''){    
    if(regex.test(this.state.dig7Name)){
      alert("dig/an 7 name can not contain special character")
      return
    }
  }
  if(this.state.dig8 === true && this.state.dig8Name !== ''){    
    if(regex.test(this.state.dig8Name)){
      alert("dig/an 8 name can not contain special character")
      return
    }
  }


  // bring all reading names to lowercase
  let an1Name = this.state.an1Name.toLowerCase().replace(/\s+/g,'_');
  let an2Name = this.state.an2Name.toLowerCase().replace(/\s+/g,'_');
  let an3Name = this.state.an3Name.toLowerCase().replace(/\s+/g,'_');
  let an4Name = this.state.an4Name.toLowerCase().replace(/\s+/g,'_');
  let dig5Name = this.state.dig5Name.toLowerCase().replace(/\s+/g,'_');
  let dig6Name = this.state.dig6Name.toLowerCase().replace(/\s+/g,'_');
  let dig7Name = this.state.dig7Name.toLowerCase().replace(/\s+/g,'_');
  let dig8Name = this.state.dig8Name.toLowerCase().replace(/\s+/g,'_');

  //convert low and high scale to offset and scale
  let an1OffsetFinal = 0
  let an1ScaleFinal = 0
  let an1AddSubFinal = true

  if(this.state.an1ScaleLow === 0){
    an1OffsetFinal = 0
    an1ScaleFinal = this.state.an1ScaleHigh
  } else {
    if((this.state.an1ScaleLow > 0 && this.state.an1ScaleHigh > 0) || (this.state.an1Scale < 0 && this.state.an1ScaleHigh < 0)){
      an1ScaleFinal = this.state.an1ScaleHigh - this.state.an1ScaleLow
    } else {
      an1ScaleFinal = (this.state.an1ScaleLow * -1) + this.state.an1ScaleHigh
    }
    if(this.state.an1ScaleLow > 0){
      an1OffsetFinal = this.state.an1ScaleLow
      an1AddSubFinal = true
    } else {
      an1OffsetFinal = this.state.an1ScaleLow * -1
      an1AddSubFinal = false
    }
  }

  let an2OffsetFinal = 0
  let an2ScaleFinal = 0
  let an2AddSubFinal = true

  if(this.state.an2ScaleLow === 0){
    an2OffsetFinal = 0
    an2ScaleFinal = this.state.an2ScaleHigh
  } else {
    if((this.state.an2ScaleLow > 0 && this.state.an2ScaleHigh > 0) || (this.state.an2Scale < 0 && this.state.an2ScaleHigh < 0)){
      an2ScaleFinal = this.state.an2ScaleHigh - this.state.an2ScaleLow
    } else {
      an2ScaleFinal = (this.state.an2ScaleLow * -1) + this.state.an2ScaleHigh
    }
    if(this.state.an2ScaleLow > 0){
      an2OffsetFinal = this.state.an2ScaleLow
      an2AddSubFinal = true
    } else {
      an2OffsetFinal = this.state.an2ScaleLow * -1
      an2AddSubFinal = false
    }
  }

  let an3OffsetFinal = 0
  let an3ScaleFinal = 0
  let an3AddSubFinal = true

  if(this.state.an3ScaleLow === 0){
    an3OffsetFinal = 0
    an3ScaleFinal = this.state.an3ScaleHigh
  } else {
    if((this.state.an3ScaleLow > 0 && this.state.an3ScaleHigh > 0) || (this.state.an3Scale < 0 && this.state.an3ScaleHigh < 0)){
      an3ScaleFinal = this.state.an3ScaleHigh - this.state.an3ScaleLow
    } else {
      an3ScaleFinal = (this.state.an3ScaleLow * -1) + this.state.an3ScaleHigh
    }
    if(this.state.an3ScaleLow > 0){
      an3OffsetFinal = this.state.an3ScaleLow
      an3AddSubFinal = true
    } else {
      an3OffsetFinal = this.state.an3ScaleLow * -1
      an3AddSubFinal = false
    }
  }

  let an4OffsetFinal = 0
  let an4ScaleFinal = 0
  let an4AddSubFinal = true

  if(this.state.an4ScaleLow === 0){
    an4OffsetFinal = 0
    an4ScaleFinal = this.state.an4ScaleHigh
  } else {
    if((this.state.an4ScaleLow > 0 && this.state.an4ScaleHigh > 0) || (this.state.an4Scale < 0 && this.state.an4ScaleHigh < 0)){
      an4ScaleFinal = this.state.an4ScaleHigh - this.state.an4ScaleLow
    } else {
      an4ScaleFinal = (this.state.an4ScaleLow * -1) + this.state.an4ScaleHigh
    }
    if(this.state.an4ScaleLow > 0){
      an4OffsetFinal = this.state.an4ScaleLow
      an4AddSubFinal = true
    } else {
      an4OffsetFinal = this.state.an4ScaleLow * -1
      an4AddSubFinal = false
    }
  }

  let digan5OffsetFinal = 0
  let digan5ScaleFinal = 0
  let digan5AddSubFinal = true

  if(this.state.digan5ScaleLow === 0){
    digan5OffsetFinal = 0
    digan5ScaleFinal = this.state.digan5ScaleHigh
  } else {
    if((this.state.digan5ScaleLow > 0 && this.state.digan5ScaleHigh > 0) || (this.state.digan5Scale < 0 && this.state.digan5ScaleHigh < 0)){
      digan5ScaleFinal = this.state.digan5ScaleHigh - this.state.digan5ScaleLow
    } else {
      digan5ScaleFinal = (this.state.digan5ScaleLow * -1) + this.state.digan5ScaleHigh
    }
    if(this.state.digan5ScaleLow > 0){
      digan5OffsetFinal = this.state.digan5ScaleLow
      digan5AddSubFinal = true
    } else {
      digan5OffsetFinal = this.state.digan5ScaleLow * -1
      digan5AddSubFinal = false
    }
  }

  let digan6OffsetFinal = 0
  let digan6ScaleFinal = 0
  let digan6AddSubFinal = true

  if(this.state.digan6ScaleLow === 0){
    digan6OffsetFinal = 0
    digan6ScaleFinal = this.state.digan6ScaleHigh
  } else {
    if((this.state.digan6ScaleLow > 0 && this.state.digan6ScaleHigh > 0) || (this.state.digan6Scale < 0 && this.state.digan6ScaleHigh < 0)){
      digan6ScaleFinal = this.state.digan6ScaleHigh - this.state.digan6ScaleLow
    } else {
      digan6ScaleFinal = (this.state.digan6ScaleLow * -1) + this.state.digan6ScaleHigh
    }
    if(this.state.digan6ScaleLow > 0){
      digan6OffsetFinal = this.state.digan6ScaleLow
      digan6AddSubFinal = true
    } else {
      digan6OffsetFinal = this.state.digan6ScaleLow * -1
      digan6AddSubFinal = false
    }
  }

  let digan7OffsetFinal = 0
  let digan7ScaleFinal = 0
  let digan7AddSubFinal = true

  if(this.state.digan7ScaleLow === 0){
    digan7OffsetFinal = 0
    digan7ScaleFinal = this.state.digan7ScaleHigh
  } else {
    if((this.state.digan7ScaleLow > 0 && this.state.digan7ScaleHigh > 0) || (this.state.digan7Scale < 0 && this.state.digan7ScaleHigh < 0)){
      digan7ScaleFinal = this.state.digan7ScaleHigh - this.state.digan7ScaleLow
    } else {
      digan7ScaleFinal = (this.state.digan7ScaleLow * -1) + this.state.digan7ScaleHigh
    }
    if(this.state.digan7ScaleLow > 0){
      digan7OffsetFinal = this.state.digan7ScaleLow
      digan7AddSubFinal = true
    } else {
      digan7OffsetFinal = this.state.digan7ScaleLow * -1
      digan7AddSubFinal = false
    }
  }

  let digan8OffsetFinal = 0
  let digan8ScaleFinal = 0
  let digan8AddSubFinal = true

  if(this.state.digan8ScaleLow === 0){
    digan8OffsetFinal = 0
    digan8ScaleFinal = this.state.digan8ScaleHigh
  } else {
    if((this.state.digan8ScaleLow > 0 && this.state.digan8ScaleHigh > 0) || (this.state.digan8Scale < 0 && this.state.digan8ScaleHigh < 0)){
      digan8ScaleFinal = this.state.digan8ScaleHigh - this.state.digan8ScaleLow
    } else {
      digan8ScaleFinal = (this.state.digan8ScaleLow * -1) + this.state.digan8ScaleHigh
    }
    if(this.state.digan8ScaleLow > 0){
      digan8OffsetFinal = this.state.digan8ScaleLow
      digan8AddSubFinal = true
    } else {
      digan8OffsetFinal = this.state.digan8ScaleLow * -1
      digan8AddSubFinal = false
    }
  }

  // time conversions - convert hours minutes and seconds to seconds X 2 for the device program
  let bounce5 = ((Number(this.state.dig5BounceHour) * 3600) + (Number(this.state.dig5BounceMin) * 60) + Number(this.state.dig5BounceSec)) * 2;
  let bounce6 = ((Number(this.state.dig6BounceHour) * 3600) + (Number(this.state.dig6BounceMin) * 60) + Number(this.state.dig6BounceSec)) * 2;
  let bounce7 = ((Number(this.state.dig7BounceHour) * 3600) + (Number(this.state.dig7BounceMin) * 60) + Number(this.state.dig7BounceSec)) * 2;
  let bounce8 = ((Number(this.state.dig8BounceHour) * 3600) + (Number(this.state.dig8BounceMin) * 60) + Number(this.state.dig8BounceSec)) * 2;


  let alarm1Time = ((Number(this.state.an1AlarmHour) * 3600) + (Number(this.state.an1AlarmMin) * 60)) * 2;
  let alarm2Time = ((Number(this.state.an2AlarmHour) * 3600) + (Number(this.state.an2AlarmMin) * 60)) * 2;
  let alarm3Time = ((Number(this.state.an3AlarmHour) * 3600) + (Number(this.state.an3AlarmMin) * 60)) * 2;
  let alarm4Time = ((Number(this.state.an4AlarmHour) * 3600) + (Number(this.state.an4AlarmMin) * 60)) * 2;
  let alarm5Time = ((Number(this.state.dig5AlarmHour) * 3600) + (Number(this.state.dig5AlarmMin) * 60)) * 2;
  let alarm6Time = ((Number(this.state.dig6AlarmHour) * 3600) + (Number(this.state.dig6AlarmMin) * 60)) * 2;
  let alarm7Time = ((Number(this.state.dig7AlarmHour) * 3600) + (Number(this.state.dig7AlarmMin) * 60)) * 2;
  let alarm8Time = ((Number(this.state.dig8AlarmHour) * 3600) + (Number(this.state.dig8AlarmMin) * 60)) * 2;


  // get current icons so they are ubdated properly on the database based on newest settings
  const getIcons = `query GetIcons {
    getBlog(id:"${this.blogid}") {
    id
    location
    icons {
      items {
        id
        value
        lowest
        low
        high
        highest
        order
      }
    }
  }
  }`


  this.icondata = await API.graphql(graphqlOperation(getIcons));

  this.modIcons = this.icondata.data.getBlog.icons.items;


  let names = [];

  if(this.state.an1 === true){
    names.push(an1Name)
  }
  if(this.state.an2 === true){
    names.push(an2Name)
  }
  if(this.state.an3 === true){
    names.push(an3Name)
  }
  if(this.state.an4 === true){
    names.push(an4Name)
  }

  if(this.state.dig5 === true){
    names.push(dig5Name)
  }
  if(this.state.dig6 === true){
    names.push(dig6Name)
  }
  if(this.state.dig7 === true){
    names.push(dig7Name)
  }
  if(this.state.dig8 === true){
    names.push(dig8Name)
  }


  let ignoreIcons = [];
  let safeIds = []


  this.modIcons.map(function(icon){
    for (let index = 0; index < names.length; ++index) {
      if(icon.value === names[index]){
        ignoreIcons.push(names[index])
        safeIds.push(icon.id)
      }
    }
    return "checked";
  })


  var iconIds = [];

  this.modIcons.map((id, index) => (
    iconIds.push(id.id)
  ));


  let iconsRemove = iconIds.filter(val => !safeIds.includes(val));


  let iconsCreate = names.filter(val => !ignoreIcons.includes(val));



  iconsRemove.map((icons, index) => {
    API.graphql(graphqlOperation(deleteIcon, { input: { id: icons }}));
    return('delete')
  });



  let addIcon = (inputName) => {

    const addedIcon = `mutation addIcon{
      createIcon(input: {
        value: "${inputName}"
        lowest: -20000
        low: -10000
        high: 10000
        highest: 20000
        iconBlogId: "${this.blogid}"
      }) {
        value
        lowest
        low
        high
        highest
      }
    }`
    this.newIcon =  API.graphql(graphqlOperation(addedIcon));
  };

  iconsCreate.map((icons, index) => {
    addIcon(icons)
    return('delete')
  });

  if (this.state.alarmOn && this.state.dig5 && this.state.dig5Alarm && !this.state.accClose5 && this.state.digan5){
    if(!this.state.closureAlarm5) {
      // open alarm
      this.setState({
        dig5AlarmHigh: 1,
        dig5AlarmLow: .55555
      })
    } else if(this.state.closureAlarm5) {
      // closed alarm
      this.setState({
        dig5AlarmHigh: .55555,
        dig5AlarmLow: 0
      })
    } else {
      console.log('error setting closure alarm settings')
    }
  }

  if (this.state.alarmOn && this.state.dig6 && this.state.dig6Alarm && !this.state.accClose6 && this.state.digan6){
    if(!this.state.closureAlarm6) {
      // open alarm
      this.setState({
        dig6AlarmHigh: 1,
        dig6AlarmLow: .55555
      })
    } else if(this.state.closureAlarm6) {
      // closed alarm
      this.setState({
        dig6AlarmHigh: .55555,
        dig6AlarmLow: 0
      })
    } else {
      console.log('error setting closure alarm settings')
    }
  }

  if (this.state.alarmOn && this.state.dig7 && this.state.dig7Alarm && !this.state.accClose7 && this.state.digan7){
    if(!this.state.closureAlarm7) {
      // open alarm
      this.setState({
        dig7AlarmHigh: 1,
        dig7AlarmLow: .55555
      })
    } else if(this.state.closureAlarm7) {
      // closed alarm
      this.setState({
        dig7AlarmHigh: .55555,
        dig7AlarmLow: 0
      })
    } else {
      console.log('error setting closure alarm settings')
    }
  }

  if (this.state.alarmOn && this.state.dig8 && this.state.dig8Alarm && !this.state.accClose8 && this.state.digan8){
    if(!this.state.closureAlarm8) {
      // open alarm
      this.setState({
        dig8AlarmHigh: 1,
        dig8AlarmLow: .55555
      })
    } else if(this.state.closureAlarm8) {
      // closed alarm
      this.setState({
        dig8AlarmHigh: .55555,
        dig8AlarmLow: 0
      })
    } else {
      console.log('error setting closure alarm settings')
    }
  }


  // json format to send as shadow
  const myInit = {
    body: {
        location: this.local,
        deviceAlertName: this.local,
        an1: this.state.an1,
        an2: this.state.an2,
        an3: this.state.an3,
        an4: this.state.an4,
        dig5: this.state.dig5,
        dig6: this.state.dig6,
        dig7: this.state.dig7,
        dig8: this.state.dig8,
        digan5: this.state.digan5,
        digan6: this.state.digan6,
        digan7: this.state.digan7,
        digan8: this.state.digan8,
        accClose5: this.state.accClose5,
        accClose6: this.state.accClose6,
        accClose7: this.state.accClose7,
        accClose8: this.state.accClose8,
        alarmOn: true,
        an1Name: an1Name,
        an1Low: this.state.an1Low,
        an1High: this.state.an1High,
        an1AddSub: an1AddSubFinal,
        an1Scale: an1ScaleFinal,
        an1Round: this.state.an1Round,
        an1Offset: an1OffsetFinal,
        an2Name: an2Name,
        an2Low: this.state.an2Low,
        an2High: this.state.an2High,
        an2Scale: an2ScaleFinal,
        an2Round: this.state.an2Round,
        an2AddSub: an2AddSubFinal,
        an2Offset: an2OffsetFinal,
        an3Name: an3Name,
        an3Low: this.state.an3Low,
        an3High: this.state.an3High,
        an3Scale: an3ScaleFinal,
        an3Round: this.state.an3Round,
        an3AddSub: an3AddSubFinal,
        an3Offset: an3OffsetFinal,
        an4Name: an4Name,
        an4Low: this.state.an4Low,
        an4High: this.state.an4High,
        an4Scale: an4ScaleFinal,
        an4Round: this.state.an4Round,
        an4AddSub: an4AddSubFinal,
        an4Offset: an4OffsetFinal,
        dig5Name: dig5Name,
        dig5Bounce: bounce5,
        dig5Value: this.state.dig5Value,
        dig5Reset: this.state.dig5Reset,
        dig5Closed: this.state.dig5Closed,
        dig5Open: this.state.dig5Open,
        digan5Low: this.state.digan5Low,
        digan5High: this.state.digan5High,
        dig5AddSub: digan5AddSubFinal,
        digan5Scale: digan5ScaleFinal,
        digan5Round: this.state.digan5Round,
        digan5Offset: digan5OffsetFinal,
        dig6Name: dig6Name,
        dig6Bounce: bounce6,
        dig6Value: this.state.dig6Value,
        dig6Reset: this.state.dig6Reset,
        dig6Closed: this.state.dig6Closed,
        dig6Open: this.state.dig6Open,
        digan6Low: this.state.digan6Low,
        digan6High: this.state.digan6High,
        digan6Scale: digan6ScaleFinal,
        digan6Round: this.state.digan6Round,
        dig6AddSub: digan6AddSubFinal,
        digan6Offset: digan6OffsetFinal,
        dig7Name: dig7Name,
        dig7Bounce: bounce7,
        dig7Value: this.state.dig7Value,
        dig7Reset: this.state.dig7Reset,
        dig7Closed: this.state.dig7Closed,
        dig7Open: this.state.dig7Open,
        digan7Low: this.state.digan7Low,
        digan7High: this.state.digan7High,
        digan7Scale: digan7ScaleFinal,
        digan7Round: this.state.digan7Round,
        dig7AddSub: digan7AddSubFinal,
        digan7Offset: digan7OffsetFinal,
        dig8Name: dig8Name,
        dig8Bounce: bounce8,
        dig8Value: this.state.dig8Value,
        dig8Reset: this.state.dig8Reset,
        dig8Closed: this.state.dig8Closed,
        dig8Open: this.state.dig8Open,
        digan8Low: this.state.digan8Low,
        digan8High: this.state.digan8High,
        digan8Scale: digan8ScaleFinal,
        digan8Round: this.state.digan8Round,
        dig8AddSub: digan8AddSubFinal,
        digan8Offset: digan8OffsetFinal,
        an1Alarm: this.state.an1Alarm,
        an2Alarm: this.state.an2Alarm,
        an3Alarm: this.state.an3Alarm,
        an4Alarm: this.state.an4Alarm,
        dig5Alarm: this.state.dig5Alarm,
        dig6Alarm: this.state.dig6Alarm,
        dig7Alarm: this.state.dig7Alarm,
        dig8Alarm: this.state.dig8Alarm,
        an1AlarmLow: this.state.an1AlarmLow,
        an1AlarmHigh: this.state.an1AlarmHigh,
        an1AlarmTime: alarm1Time,
        an2AlarmLow: this.state.an2AlarmLow,
        an2AlarmHigh: this.state.an2AlarmHigh,
        an2AlarmTime: alarm2Time,
        an3AlarmLow: this.state.an3AlarmLow,
        an3AlarmHigh: this.state.an3AlarmHigh,
        an3AlarmTime: alarm3Time,
        an4AlarmLow: this.state.an4AlarmLow,
        an4AlarmHigh: this.state.an4AlarmHigh,
        an4AlarmTime: alarm4Time,
        dig5AlarmLow: this.state.dig5AlarmLow,
        dig5AlarmHigh: this.state.dig5AlarmHigh,
        dig5AlarmTime: alarm5Time,
        dig6AlarmLow: this.state.dig6AlarmLow,
        dig6AlarmHigh: this.state.dig6AlarmHigh,
        dig6AlarmTime: alarm6Time,
        dig7AlarmLow: this.state.dig7AlarmLow,
        dig7AlarmHigh: this.state.dig7AlarmHigh,
        dig7AlarmTime: alarm7Time,
        dig8AlarmLow: this.state.dig8AlarmLow,
        dig8AlarmHigh: this.state.dig8AlarmHigh,
        dig8AlarmTime: alarm8Time
    }


};

  function setData() {
    const apiName = 'ShadowApi';
    const path = '/shadow';

    return API.put(apiName, path, myInit);
  }

  try {
    await setData();
  } catch(error) {
    alert('error occured programming device check your internet connection and try again')
  }


  alert("device settings updated");

};

async componentDidMount(){
  Auth.currentAuthenticatedUser({
    bypassCache: false
    }).then(user => {
      this.loggedIn = true
    }
  )
  .catch(error => {
    if(error === 'not authenticated'){
      this.props.history.push('/SignIn');
    }
  }
  );

  const local = this.local;

  let user = await Auth.currentAuthenticatedUser()
  this.userName = user.username

  this.blogCall = await API.graphql(graphqlOperation(queries.listBlogs, {
    limit: 1000,
    filter: {
      location:{
        eq: this.local
      }
    }
    }));

  //set the id to this.blogID
  this.blogid = this.blogCall.data.listBlogs.items[0].id

  function getData() {
    const apiName = 'ShadowApi';
    const path = '/shadow';
    const myInit = {
      queryStringParameters: {
          location: local
      }
  };
    return API.get(apiName, path, myInit);
  }


  // get current shadow and if no shadow found return blank form
  let response

  try{
    response = await getData();
  }
  catch(err){
    this.setState({
      loading: false
    });
    return
  }



  let jsonData = response.success;
  let jsonString = JSON.parse(jsonData.payload);
  let jsonState = jsonString.state.desired;

  if(Number(jsonState['dig5AlarmLow']) === .55555){
    this.setState({
      closureAlarm5: false
    })
  }
  if(Number(jsonState['dig5AlarmHigh']) === .55555){
    this.setState({
      closureAlarm5: true
    })
  }

  if(Number(jsonState['dig6AlarmLow']) === .55555){
    this.setState({
      closureAlarm6: false
    })
  }
  if(Number(jsonState['dig6AlarmHigh']) === .55555){
    this.setState({
      closureAlarm6: true
    })
  }

  if(Number(jsonState['dig7AlarmLow']) === .55555){
    this.setState({
      closureAlarm7: false
    })
  }
  if(Number(jsonState['dig7AlarmHigh']) === .55555){
    this.setState({
      closureAlarm7: true
    })
  }

  if(Number(jsonState['dig8AlarmLow']) === .55555){
    this.setState({
      closureAlarm8: false
    })
  }
  if(Number(jsonState['dig8AlarmHigh']) === .55555){
    this.setState({
      closureAlarm8: true
    })
  }


  // convert returned offset and scale to low scale and high scale and fill in form with results
  let an1ScaleLowReturned = ''
  let an1ScaleHighReturned = ''

  let an1AddSubReturned = JSON.parse(jsonState['an1AddSub']);
  let an1OffsetReturned = Number(jsonState['an1Offset']);
  let an1ScaleReturned = Number(jsonState['an1Scale']);

  if(an1OffsetReturned === 0){
    an1ScaleLowReturned = 0
    an1ScaleHighReturned = an1ScaleReturned
  } else {
    if(an1AddSubReturned === true || an1AddSubReturned === 'true'){
      an1ScaleLowReturned = an1OffsetReturned
      an1ScaleHighReturned = an1ScaleReturned + an1OffsetReturned
    } else {
      if(an1OffsetReturned > an1ScaleHighReturned){
        an1ScaleLowReturned = an1OffsetReturned * -1
        an1ScaleHighReturned = (an1OffsetReturned * -1) + an1ScaleReturned
      } else {
        an1ScaleLowReturned = an1OffsetReturned * -1
        an1ScaleHighReturned = an1OffsetReturned + an1ScaleHighReturned
      }
    }
  }

  let an2ScaleLowReturned = ''
  let an2ScaleHighReturned = ''

  let an2AddSubReturned = JSON.parse(jsonState['an2AddSub']);
  let an2OffsetReturned = Number(jsonState['an2Offset']);
  let an2ScaleReturned = Number(jsonState['an2Scale']);

  if(an2OffsetReturned === 0){
    an2ScaleLowReturned = 0
    an2ScaleHighReturned = an2ScaleReturned
  } else {
    if(an2AddSubReturned === true || an2AddSubReturned === 'true'){
      an2ScaleLowReturned = an2OffsetReturned
      an2ScaleHighReturned = an2ScaleReturned + an2OffsetReturned
    } else {
      if(an2OffsetReturned > an2ScaleHighReturned){
        an2ScaleLowReturned = an2OffsetReturned * -1
        an2ScaleHighReturned = (an2OffsetReturned * -1) + an2ScaleReturned
      } else {
        an2ScaleLowReturned = an2OffsetReturned * -1
        an2ScaleHighReturned = an2OffsetReturned + an2ScaleHighReturned
      }
    }
  }


  let an3ScaleLowReturned = ''
  let an3ScaleHighReturned = ''

  let an3AddSubReturned = JSON.parse(jsonState['an3AddSub']);
  let an3OffsetReturned = Number(jsonState['an3Offset']);
  let an3ScaleReturned = Number(jsonState['an3Scale']);

  if(an3OffsetReturned === 0){
    an3ScaleLowReturned = 0
    an3ScaleHighReturned = an3ScaleReturned
  } else {
    if(an3AddSubReturned === true || an3AddSubReturned === 'true'){
      an3ScaleLowReturned = an3OffsetReturned
      an3ScaleHighReturned = an3ScaleReturned + an3OffsetReturned
    } else {
      if(an3OffsetReturned > an3ScaleHighReturned){
        an3ScaleLowReturned = an3OffsetReturned * -1
        an3ScaleHighReturned = (an3OffsetReturned * -1) + an3ScaleReturned
      } else {
        an3ScaleLowReturned = an3OffsetReturned * -1
        an3ScaleHighReturned = an3OffsetReturned + an3ScaleHighReturned
      }
    }
  }

  let an4ScaleLowReturned = ''
  let an4ScaleHighReturned = ''

  let an4AddSubReturned = JSON.parse(jsonState['an4AddSub']);
  let an4OffsetReturned = Number(jsonState['an4Offset']);
  let an4ScaleReturned = Number(jsonState['an4Scale']);

  if(an4OffsetReturned === 0){
    an4ScaleLowReturned = 0
    an4ScaleHighReturned = an4ScaleReturned
  } else {
    if(an4AddSubReturned === true || an4AddSubReturned === 'true'){
      an4ScaleLowReturned = an4OffsetReturned
      an4ScaleHighReturned = an4ScaleReturned + an4OffsetReturned
    } else {
      if(an4OffsetReturned > an4ScaleHighReturned){
        an4ScaleLowReturned = an4OffsetReturned * -1
        an4ScaleHighReturned = (an4OffsetReturned * -1) + an4ScaleReturned
      } else {
        an4ScaleLowReturned = an4OffsetReturned * -1
        an4ScaleHighReturned = an4OffsetReturned + an4ScaleHighReturned
      }
    }
  }

  let digan5ScaleLowReturned = ''
  let digan5ScaleHighReturned = ''

  let digan5AddSubReturned = JSON.parse(jsonState['dig5AddSub']);
  let digan5OffsetReturned = Number(jsonState['digan5Offset']);
  let digan5ScaleReturned = Number(jsonState['digan5Scale']);

  if(digan5OffsetReturned === 0){
    digan5ScaleLowReturned = 0
    digan5ScaleHighReturned = digan5ScaleReturned
  } else {
    if(digan5AddSubReturned === true || digan5AddSubReturned === 'true'){
      digan5ScaleLowReturned = digan5OffsetReturned
      digan5ScaleHighReturned = digan5ScaleReturned + digan5OffsetReturned
    } else {
      if(digan5OffsetReturned > digan5ScaleHighReturned){
        digan5ScaleLowReturned = digan5OffsetReturned * -1
        digan5ScaleHighReturned = (digan5OffsetReturned * -1) + digan5ScaleReturned
      } else {
        digan5ScaleLowReturned = digan5OffsetReturned * -1
        digan5ScaleHighReturned = digan5OffsetReturned + digan5ScaleHighReturned
      }
    }
  }

  let digan6ScaleLowReturned = ''
  let digan6ScaleHighReturned = ''

  let digan6AddSubReturned = JSON.parse(jsonState['dig6AddSub']);
  let digan6OffsetReturned = Number(jsonState['digan6Offset']);
  let digan6ScaleReturned = Number(jsonState['digan6Scale']);

  if(digan6OffsetReturned === 0){
    digan6ScaleLowReturned = 0
    digan6ScaleHighReturned = digan6ScaleReturned
  } else {
    if(digan6AddSubReturned === true || digan6AddSubReturned === 'true'){
      digan6ScaleLowReturned = digan6OffsetReturned
      digan6ScaleHighReturned = digan6ScaleReturned + digan6OffsetReturned
    } else {
      if(digan6OffsetReturned > digan6ScaleHighReturned){
        digan6ScaleLowReturned = digan6OffsetReturned * -1
        digan6ScaleHighReturned = (digan6OffsetReturned * -1) + digan6ScaleReturned
      } else {
        digan6ScaleLowReturned = digan6OffsetReturned * -1
        digan6ScaleHighReturned = digan6OffsetReturned + digan6ScaleHighReturned
      }
    }
  }

  let digan7ScaleLowReturned = ''
  let digan7ScaleHighReturned = ''

  let digan7AddSubReturned = JSON.parse(jsonState['dig7AddSub']);
  let digan7OffsetReturned = Number(jsonState['digan7Offset']);
  let digan7ScaleReturned = Number(jsonState['digan7Scale']);

  if(digan7OffsetReturned === 0){
    digan7ScaleLowReturned = 0
    digan7ScaleHighReturned = digan7ScaleReturned
  } else {
    if(digan7AddSubReturned === true || digan7AddSubReturned === 'true'){
      digan7ScaleLowReturned = digan7OffsetReturned
      digan7ScaleHighReturned = digan7ScaleReturned + digan7OffsetReturned
    } else {
      if(digan7OffsetReturned > digan7ScaleHighReturned){
        digan7ScaleLowReturned = digan7OffsetReturned * -1
        digan7ScaleHighReturned = (digan7OffsetReturned * -1) + digan7ScaleReturned
      } else {
        digan7ScaleLowReturned = digan7OffsetReturned * -1
        digan7ScaleHighReturned = digan7OffsetReturned + digan7ScaleHighReturned
      }
    }
  }

  let digan8ScaleLowReturned = ''
  let digan8ScaleHighReturned = ''

  let digan8AddSubReturned = JSON.parse(jsonState['dig8AddSub']);
  let digan8OffsetReturned = Number(jsonState['digan8Offset']);
  let digan8ScaleReturned = Number(jsonState['digan8Scale']);

  if(digan8OffsetReturned === 0){
    digan8ScaleLowReturned = 0
    digan8ScaleHighReturned = digan8ScaleReturned
  } else {
    if(digan8AddSubReturned === true || digan8AddSubReturned === 'true'){
      digan8ScaleLowReturned = digan8OffsetReturned
      digan8ScaleHighReturned = digan8ScaleReturned + digan8OffsetReturned
    } else {
      if(digan8OffsetReturned > digan8ScaleHighReturned){
        digan8ScaleLowReturned = digan8OffsetReturned * -1
        digan8ScaleHighReturned = (digan8OffsetReturned * -1) + digan8ScaleReturned
      } else {
        digan8ScaleLowReturned = digan8OffsetReturned * -1
        digan8ScaleHighReturned = digan8OffsetReturned + digan8ScaleHighReturned
      }
    }
  }


  let dig5FullBounce = Number(jsonState['dig5Bounce']) / 2;
  let dig5Hour = Math.floor(dig5FullBounce / 3600);
  let dig5Min = Math.floor(dig5FullBounce % 3600 / 60);
  let dig5Sec = dig5FullBounce % 60;

  let dig6FullBounce = Number(jsonState['dig6Bounce']) / 2;
  let dig6Hour = Math.floor(dig6FullBounce / 3600);
  let dig6Min = Math.floor(dig6FullBounce % 3600 / 60);
  let dig6Sec = dig6FullBounce % 60;

  let dig7FullBounce = Number(jsonState['dig7Bounce']) / 2;
  let dig7Hour = Math.floor(dig7FullBounce / 3600);
  let dig7Min = Math.floor(dig7FullBounce % 3600 / 60);
  let dig7Sec = dig7FullBounce % 60;

  let dig8FullBounce = Number(jsonState['dig8Bounce']) / 2;
  let dig8Hour = Math.floor(dig8FullBounce / 3600);
  let dig8Min = Math.floor(dig8FullBounce % 3600 / 60);
  let dig8Sec = dig8FullBounce % 60;

  let an1AlarmFullTime = Number(jsonState['an1AlarmTime']) / 2;
  let an1Hour = Math.floor(an1AlarmFullTime / 3600);
  let an1Min = Math.floor(an1AlarmFullTime % 3600 / 60);

  let an2AlarmFullTime = Number(jsonState['an2AlarmTime']) / 2;
  let an2Hour = Math.floor(an2AlarmFullTime / 3600);
  let an2Min = Math.floor(an2AlarmFullTime % 3600 / 60);

  let an3AlarmFullTime = Number(jsonState['an3AlarmTime']) / 2;
  let an3Hour = Math.floor(an3AlarmFullTime / 3600);
  let an3Min = Math.floor(an3AlarmFullTime % 3600 / 60);

  let an4AlarmFullTime = Number(jsonState['an4AlarmTime']) / 2;
  let an4Hour = Math.floor(an4AlarmFullTime / 3600);
  let an4Min = Math.floor(an4AlarmFullTime % 3600 / 60);

  let dig5AlarmFullTime = Number(jsonState['dig5AlarmTime']) / 2;
  let diga5Hour = Math.floor(dig5AlarmFullTime / 3600);
  let diga5Min = Math.floor(dig5AlarmFullTime % 3600 / 60);

  let dig6AlarmFullTime = Number(jsonState['dig6AlarmTime']) / 2;
  let diga6Hour = Math.floor(dig6AlarmFullTime / 3600);
  let diga6Min = Math.floor(dig6AlarmFullTime % 3600 / 60);

  let dig7AlarmFullTime = Number(jsonState['dig7AlarmTime']) / 2;
  let diga7Hour = Math.floor(dig7AlarmFullTime / 3600);
  let diga7Min = Math.floor(dig7AlarmFullTime % 3600 / 60);

  let dig8AlarmFullTime = Number(jsonState['dig8AlarmTime']) / 2;
  let diga8Hour = Math.floor(dig8AlarmFullTime / 3600);
  let diga8Min = Math.floor(dig8AlarmFullTime % 3600 / 60);



  this.setState({
    deviceAlertName: this.local,
    an1: JSON.parse(jsonState['an1']),
    an2: JSON.parse(jsonState['an2']),
    an3: JSON.parse(jsonState['an3']),
    an4: JSON.parse(jsonState['an4']),
    dig5: JSON.parse(jsonState['dig5']),
    dig6: JSON.parse(jsonState['dig6']),
    dig7: JSON.parse(jsonState['dig7']),
    dig8: JSON.parse(jsonState['dig8']),
    digan5: JSON.parse(jsonState['digan5']),
    digan6: JSON.parse(jsonState['digan6']),
    digan7: JSON.parse(jsonState['digan7']),
    digan8: JSON.parse(jsonState['digan8']),
    accClose5: JSON.parse(jsonState['accClose5']),
    accClose6: JSON.parse(jsonState['accClose6']),
    accClose7: JSON.parse(jsonState['accClose7']),
    accClose8: JSON.parse(jsonState['accClose8']),
    alarmOn: JSON.parse(jsonState['alarmOn']),
    an1Alarm: true,
    an2Alarm: true,
    an3Alarm: true,
    an4Alarm: true,
    dig5Alarm: true,
    dig6Alarm: true,
    dig7Alarm: true,
    dig8Alarm: true,
    an1Name: jsonState['an1Name'],
    an1Low: jsonState['an1Low'],
    an1High: jsonState['an1High'],
    an1Scale: jsonState['an1Scale'],
    an1Round: jsonState['an1Round'],
    an1AddSub: JSON.parse(jsonState['an1AddSub']),
    an1Offset: jsonState['an1Offset'],
    an1ScaleLow: an1ScaleLowReturned,
    an1ScaleHigh: an1ScaleHighReturned,
    an2Name: jsonState['an2Name'],
    an2Low: jsonState['an2Low'],
    an2High: jsonState['an2High'],
    an2Scale: jsonState['an2Scale'],
    an2Round: jsonState['an2Round'],
    an2AddSub: JSON.parse(jsonState['an2AddSub']),
    an2Offset: jsonState['an2Offset'],
    an2ScaleLow: an2ScaleLowReturned,
    an2ScaleHigh: an2ScaleHighReturned,
    an3Name: jsonState['an3Name'],
    an3Low: jsonState['an3Low'],
    an3High: jsonState['an3High'],
    an3Scale: jsonState['an3Scale'],
    an3Round: jsonState['an3Round'],
    an3AddSub: JSON.parse(jsonState['an3AddSub']),
    an3Offset: jsonState['an3Offset'],
    an3ScaleLow: an3ScaleLowReturned,
    an3ScaleHigh: an3ScaleHighReturned,
    an4Name: jsonState['an4Name'],
    an4Low: jsonState['an4Low'],
    an4High: jsonState['an4High'],
    an4Scale: jsonState['an4Scale'],
    an4Round: jsonState['an4Round'],
    an4AddSub: JSON.parse(jsonState['an4AddSub']),
    an4Offset: jsonState['an4Offset'],
    an4ScaleLow: an4ScaleLowReturned,
    an4ScaleHigh: an4ScaleHighReturned,
    dig5Name: jsonState['dig5Name'],
    dig5Bounce: jsonState['dig5Bounce'],
    dig5BounceHour: dig5Hour,
    dig5BounceMin: dig5Min,
    dig5BounceSec: dig5Sec,
    dig5Value: jsonState['dig5Value'],
    dig5Reset: JSON.parse(jsonState['dig5Reset']),
    dig5Closed: 1,
    dig5Open: 0,
    digan5Low: jsonState['digan5Low'],
    digan5High: jsonState['digan5High'],
    digan5Scale: jsonState['digan5Scale'],
    digan5Round: jsonState['digan5Round'],
    digan5Offset: jsonState['digan5Offset'],
    dig5AddSub: JSON.parse(jsonState['dig5AddSub']),
    digan5ScaleLow: digan5ScaleLowReturned,
    digan5ScaleHigh: digan5ScaleHighReturned,
    dig6Name: jsonState['dig6Name'],
    dig6Bounce: jsonState['dig6Bounce'],
    dig6BounceHour: dig6Hour,
    dig6BounceMin: dig6Min,
    dig6BounceSec: dig6Sec,
    dig6Value: jsonState['dig6Value'],
    dig6Reset: JSON.parse(jsonState['dig6Reset']),
    dig6Closed: 1,
    dig6Open: 0,
    digan6Low: jsonState['digan6Low'],
    digan6High: jsonState['digan6High'],
    digan6Scale: jsonState['digan6Scale'],
    digan6Round: jsonState['digan6Round'],
    dig6AddSub: JSON.parse(jsonState['dig6AddSub']),
    digan6Offset: jsonState['digan6Offset'],
    digan6ScaleLow: digan6ScaleLowReturned,
    digan6ScaleHigh: digan6ScaleHighReturned,
    dig7Name: jsonState['dig7Name'],
    dig7Bounce: jsonState['dig7Bounce'],
    dig7BounceHour: dig7Hour,
    dig7BounceMin: dig7Min,
    dig7BounceSec: dig7Sec,
    dig7Value: jsonState['dig7Value'],
    dig7Reset: JSON.parse(jsonState['dig7Reset']),
    dig7Closed: 1,
    dig7Open: 0,
    digan7Low: jsonState['digan7Low'],
    digan7High: jsonState['digan7High'],
    digan7Scale: jsonState['digan7Scale'],
    digan7Round: jsonState['digan7Round'],
    dig7AddSub: JSON.parse(jsonState['dig7AddSub']),
    digan7Offset: jsonState['digan7Offset'],
    digan7ScaleLow: digan7ScaleLowReturned,
    digan7ScaleHigh: digan7ScaleHighReturned,
    dig8Name: jsonState['dig8Name'],
    dig8Bounce: jsonState['dig8Bounce'],
    dig8BounceHour: dig8Hour,
    dig8BounceMin: dig8Min,
    dig8BounceSec: dig8Sec,
    dig8Value: jsonState['dig8Value'],
    dig8Reset: JSON.parse(jsonState['dig8Reset']),
    dig8Closed: 1,
    dig8Open: 0,
    digan8Low: jsonState['digan8Low'],
    digan8High: jsonState['digan8High'],
    digan8Scale: jsonState['digan8Scale'],
    digan8Round: jsonState['digan8Round'],
    dig8AddSub: JSON.parse(jsonState['dig8AddSub']),
    digan8Offset: jsonState['digan8Offset'],
    digan8ScaleLow: digan8ScaleLowReturned,
    digan8ScaleHigh: digan8ScaleHighReturned,
    an1AlarmLow: jsonState['an1AlarmLow'],
    an1AlarmHigh: jsonState['an1AlarmHigh'],
    an1AlarmTime: jsonState['an1AlarmTime'],
    an1AlarmHour: an1Hour,
    an1AlarmMin: an1Min,
    an2AlarmLow: jsonState['an2AlarmLow'],
    an2AlarmHigh: jsonState['an2AlarmHigh'],
    an2AlarmTime: jsonState['an2AlarmTime'],
    an2AlarmHour: an2Hour,
    an2AlarmMin: an2Min,
    an3AlarmLow: jsonState['an3AlarmLow'],
    an3AlarmHigh: jsonState['an3AlarmHigh'],
    an3AlarmTime: jsonState['an3AlarmTime'],
    an3AlarmHour: an3Hour,
    an3AlarmMin: an3Min,
    an4AlarmLow: jsonState['an4AlarmLow'],
    an4AlarmHigh: jsonState['an4AlarmHigh'],
    an4AlarmTime: jsonState['an4AlarmTime'],
    an4AlarmHour: an4Hour,
    an4AlarmMin: an4Min,
    dig5AlarmLow: jsonState['dig5AlarmLow'],
    dig5AlarmHigh: jsonState['dig5AlarmHigh'],
    dig5AlarmTime: jsonState['dig5AlarmTime'],
    dig5AlarmHour: diga5Hour,
    dig5AlarmMin: diga5Min,
    dig6AlarmLow: jsonState['dig6AlarmLow'],
    dig6AlarmHigh: jsonState['dig6AlarmHigh'],
    dig6AlarmTime: jsonState['dig6AlarmTime'],
    dig6AlarmHour: diga6Hour,
    dig6AlarmMin: diga6Min,
    dig7AlarmLow: jsonState['dig7AlarmLow'],
    dig7AlarmHigh: jsonState['dig7AlarmHigh'],
    dig7AlarmTime: jsonState['dig7AlarmTime'],
    dig7AlarmHour: diga7Hour,
    dig7AlarmMin: diga7Min,
    dig8AlarmLow: jsonState['dig8AlarmLow'],
    dig8AlarmHigh: jsonState['dig8AlarmHigh'],
    dig8AlarmTime: jsonState['dig8AlarmTime'],
    dig8AlarmHour: diga8Hour,
    dig8AlarmMin: diga8Min,
    loading: false
  })

}


  render() {

    var backIcon = require('../Back-01.png');

    return(
      <div className="App">
      {
        <div className="Nav-header">
          <button className="Back-wrap">
          <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Settings', state: { loc: this.local, title: this.title, org: this.org, version: this.version} }}>
              <img className="Back-button" src={backIcon} alt="back" />
          </Link>
          </button>
        </div>
      }
      <br />

      <div className="Form">
      <div className="Status-wrapper">
        <br />
        <div className="Title">Program Device</div>
        <br />
      </div>
      <br />
      <div style={{fontSize: '1.5em', fontWeight: 'bold'}}>{this.title}</div>
      <div style={{fontSize: '1.17em', fontWeight: 'bold', marginBottom: '1em'}}>{this.local}</div>

      { this.state.loading === true &&
        <div className="Loading-button">Loading...</div>
      }

      { this.state.loading === false &&
        <div className="input-wrapper">
          <div>
            analog 1<input type='checkbox' name='an1' onChange={(e) => {this.setState({an1: !this.state.an1})}} checked={this.state.an1} />&nbsp;&nbsp;
            <input type='checkbox' name='dig5' onChange={(e) => {this.setState({dig5: !this.state.dig5})}} checked={this.state.dig5} />dig/an 5
          </div>
          <br />
          <div>
            analog 2<input type='checkbox' name='an2' onChange={(e) => {this.setState({an2: !this.state.an2})}} checked={this.state.an2} />&nbsp;&nbsp;
            <input type='checkbox' name='dig6' onChange={(e) => {this.setState({dig6: !this.state.dig6})}} checked={this.state.dig6} />dig/an 6
          </div>
          <br />
          <div>
            analog 3<input type='checkbox' name='an3' onChange={(e) => {this.setState({an3: !this.state.an3})}} checked={this.state.an3} />&nbsp;&nbsp;
            <input type='checkbox' name='dig7' onChange={(e) => {this.setState({dig7: !this.state.dig7})}} checked={this.state.dig7} />dig/an 7
          </div>
          <br />
          <div>
            analog 4<input type='checkbox' name='an4' onChange={(e) => {this.setState({an4: !this.state.an4})}} checked={this.state.an4} />&nbsp;&nbsp;
            <input type='checkbox' name='dig8' onChange={(e) => {this.setState({dig8: !this.state.dig8})}} checked={this.state.dig8} />dig/an 8
          </div>
        </div>
      }

      {this.state.an1 === true &&
        <div>
          <form>
            <div className="Status-wrapper">
            <h1>analog input 1</h1>
            </div>
            <div className="form-name">name</div>
            <input name="an1Name" className="Reading-name" type="string" value={this.state.an1Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an1Low" className="Analog-box" type="string" value={this.state.an1Low} onChange={this.handleChange}></input>

              <input name="an1High" className="Analog-box" type="string" value={this.state.an1High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an1ScaleLow" className="Analog-box" type="string" value={this.state.an1ScaleLow} onChange={this.handleChange}></input>

              <input name="an1ScaleHigh" className="Analog-box" type="string" value={this.state.an1ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="an1Round" className="Icon-box-form" type="string" value={this.state.an1Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.an2 === true &&
        <div>
          <form>
            <div className="Status-wrapper">
            <h1>analog input 2</h1>
            </div>
            <div className="form-name">name</div>
            <input name="an2Name" className="Reading-name" type="string" value={this.state.an2Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an2Low" className="Analog-box" type="string" value={this.state.an2Low} onChange={this.handleChange}></input>

              <input name="an2High" className="Analog-box" type="string" value={this.state.an2High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an2ScaleLow" className="Analog-box" type="string" value={this.state.an2ScaleLow} onChange={this.handleChange}></input>

              <input name="an2ScaleHigh" className="Analog-box" type="string" value={this.state.an2ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="an2Round" className="Icon-box-form" type="string" value={this.state.an2Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.an3 === true &&
        <div>
          <form>
            <div className="Status-wrapper">
            <h1>analog input 3</h1>
            </div>
            <div className="form-name">name</div>
            <input name="an3Name" className="Reading-name" type="string" value={this.state.an3Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an3Low" className="Analog-box" type="string" value={this.state.an3Low} onChange={this.handleChange}></input>

              <input name="an3High" className="Analog-box" type="string" value={this.state.an3High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an3ScaleLow" className="Analog-box" type="string" value={this.state.an3ScaleLow} onChange={this.handleChange}></input>

              <input name="an3ScaleHigh" className="Analog-box" type="string" value={this.state.an3ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="an3Round" className="Icon-box-form" type="string" value={this.state.an3Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.an4 === true &&
        <div>
          <form>
            <div className="Status-wrapper">
            <h1>analog input 4</h1>
            </div>
            <div className="form-name">name</div>
            <input name="an4Name" className="Reading-name" type="string" value={this.state.an4Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an4Low" className="Analog-box" type="string" value={this.state.an4Low} onChange={this.handleChange}></input>

              <input name="an4High" className="Analog-box" type="string" value={this.state.an4High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="an4ScaleLow" className="Analog-box" type="string" value={this.state.an4ScaleLow} onChange={this.handleChange}></input>

              <input name="an4ScaleHigh" className="Analog-box" type="string" value={this.state.an4ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="an4Round" className="Icon-box-form" type="string" value={this.state.an4Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.dig5 === true &&
        <div>
          <div className="Status-wrapper">
          <h1>dig/an input 5</h1>
          </div>
          <div className="form-name">name</div>
          <input name="dig5Name" className="Reading-name" type="string" value={this.state.dig5Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
          <br />
          <br />
          <div>
          <div className="form-text-labels">digital</div>
          <div className="form-text-labels">analog</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan5' onChange={(e) => {this.setState({digan5: !this.state.digan5})}} checked={this.state.digan5} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan5' onChange={(e) => {this.setState({digan5: !this.state.digan5})}} checked={!this.state.digan5} />
          </div>
        </div>
      }
      {this.state.dig5 === true && this.state.digan5 === true &&
        <div>
          <form>
            <br />
            <div className="form-text">closure detected lock out time</div>
            <br />
            <div>
            <div className="form-text-labels">hours</div>
            <div className="form-text-labels">minutes</div>
            <div className="form-text-labels">seconds</div>
            </div>
            <input name="dig5BounceHour" className="Icon-box" type="string" value={this.state.dig5BounceHour} onChange={this.handleChange}></input>
            <input name="dig5BounceMin" className="Icon-box2" type="string" value={this.state.dig5BounceMin} onChange={this.handleChange}></input>
            <input name="dig5BounceSec" className="Icon-box3" type="string" value={this.state.dig5BounceSec} onChange={this.handleChange}></input>
          </form>

          <br />
          <div>
          <div className="form-text-labels">closure</div>
          <div className="form-text-labels">accumulator</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose5' onChange={(e) => {this.setState({accClose5: !this.state.accClose5})}} checked={!this.state.accClose5} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose5' onChange={(e) => {this.setState({accClose5: !this.state.accClose5})}} checked={this.state.accClose5} />
          </div>
        </div>
      }
      {this.state.dig5 === true && this.state.digan5 === true && this.state.accClose5 === true &&
        <div>
          <br />
          <div className="form-text">Each closure equals</div>
          <form>
            <input name="dig5Value" className="Icon-box-form" type="string" value={this.state.dig5Value} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-text">reset at midnight</div>
            <input name="dig5Reset" type="checkbox" onChange={(e) => {this.setState({dig5Reset: !this.state.dig5Reset})}} checked={this.state.dig5Reset}/>
          </form>
        </div>
      }
      {this.state.dig5 === true && this.state.digan5 === true && this.state.accClose5 === false &&
        <div>
          <br />
          <div>
          <div className="form-text-labels">open</div>
          <div className="form-text-labels">closed</div>
          </div>
          <form>
            <div className="form-text-labels">{this.state.dig5Open}</div>
            <div className="form-text-labels">{this.state.dig5Closed}</div>
          </form>
        </div>
      }
      {this.state.dig5 === true && this.state.digan5 === false &&
        <div>
          <form>
            <br />
            <div className="form-text">low volt only(-2.4V to +2.4V)</div>
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan5Low" className="Analog-box" type="string" value={this.state.digan5Low} onChange={this.handleChange}></input>

              <input name="digan5High" className="Analog-box" type="string" value={this.state.digan5High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan5ScaleLow" className="Analog-box" type="string" value={this.state.digan5ScaleLow} onChange={this.handleChange}></input>

              <input name="digan5ScaleHigh" className="Analog-box" type="string" value={this.state.digan5ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="digan5Round" className="Icon-box-form" type="string" value={this.state.digan5Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.dig6 === true &&
        <div>
          <div className="Status-wrapper">
          <h1>dig/an input 6</h1>
          </div>
          <div className="form-name">name</div>
          <input name="dig6Name" className="Reading-name" type="string" value={this.state.dig6Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
          <br />
          <br />
          <div>
          <div className="form-text-labels">digital</div>
          <div className="form-text-labels">analog</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan6' onChange={(e) => {this.setState({digan6: !this.state.digan6})}} checked={this.state.digan6} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan6' onChange={(e) => {this.setState({digan6: !this.state.digan6})}} checked={!this.state.digan6} />
          </div>
        </div>
      }
      {this.state.dig6 === true && this.state.digan6 === true &&
        <div>
          <form>
            <br />
            <div className="form-text">closure detected lock out time</div>
            <br />
            <div>
            <div className="form-text-labels">hours</div>
            <div className="form-text-labels">minutes</div>
            <div className="form-text-labels">seconds</div>
            </div>
            <input name="dig6BounceHour" className="Icon-box" type="string" value={this.state.dig6BounceHour} onChange={this.handleChange}></input>
            <input name="dig6BounceMin" className="Icon-box2" type="string" value={this.state.dig6BounceMin} onChange={this.handleChange}></input>
            <input name="dig6BounceSec" className="Icon-box3" type="string" value={this.state.dig6BounceSec} onChange={this.handleChange}></input>
          </form>

          <br />
          <div>
          <div className="form-text-labels">closure</div>
          <div className="form-text-labels">accumulator</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose6' onChange={(e) => {this.setState({accClose6: !this.state.accClose6})}} checked={!this.state.accClose6} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose6' onChange={(e) => {this.setState({accClose6: !this.state.accClose6})}} checked={this.state.accClose6} />
          </div>
        </div>
      }
      {this.state.dig6 === true && this.state.digan6 === true && this.state.accClose6 === true &&
        <div>
        <br />
          <div className="form-text">Each closure equals</div>
          <form>
            <input name="dig6Value" className="Icon-box-form" type="string" value={this.state.dig6Value} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-text">reset at midnight</div>
            <input name="dig6Reset" type="checkbox" onChange={(e) => {this.setState({dig6Reset: !this.state.dig6Reset})}} checked={this.state.dig6Reset}/>
          </form>
        </div>
      }
      {this.state.dig6 === true && this.state.digan6 === true && this.state.accClose6 === false &&
        <div>
          <br />
          <div>
          <div className="form-text-labels">open</div>
          <div className="form-text-labels">closed</div>
          </div>
          <form>
          <div className="form-text-labels">{this.state.dig6Open}</div>
          <div className="form-text-labels">{this.state.dig6Closed}</div>
          </form>
        </div>
      }
      {this.state.dig6 === true && this.state.digan6 === false &&
        <div>
          <form>
            <br />
            <div className="form-text">low volt only(-2.4V to +2.4V)</div>
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan6Low" className="Analog-box" type="string" value={this.state.digan6Low} onChange={this.handleChange}></input>

              <input name="digan6High" className="Analog-box" type="string" value={this.state.digan6High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan6ScaleLow" className="Analog-box" type="string" value={this.state.digan6ScaleLow} onChange={this.handleChange}></input>

              <input name="digan6ScaleHigh" className="Analog-box" type="string" value={this.state.digan6ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="digan6Round" className="Icon-box-form" type="string" value={this.state.digan6Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.dig7 === true &&
        <div>
          <div className="Status-wrapper">
          <h1>dig/an input 7</h1>
          </div>
          <div className="form-name">name</div>
          <input name="dig7Name" className="Reading-name" type="string" value={this.state.dig7Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
          <br />
          <br />
          <div>
          <div className="form-text-labels">digital</div>
          <div className="form-text-labels">analog</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan7' onChange={(e) => {this.setState({digan7: !this.state.digan7})}} checked={this.state.digan7} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan7' onChange={(e) => {this.setState({digan7: !this.state.digan7})}} checked={!this.state.digan7} />
          </div>
        </div>
      }
      {this.state.dig7 === true && this.state.digan7 === true &&
        <div>
          <form>
            <br />
            <div className="form-text">closure detected lock out time</div>
            <br />
            <div>
            <div className="form-text-labels">hours</div>
            <div className="form-text-labels">minutes</div>
            <div className="form-text-labels">seconds</div>
            </div>
            <input name="dig7BounceHour" className="Icon-box" type="string" value={this.state.dig7BounceHour} onChange={this.handleChange}></input>
            <input name="dig7BounceMin" className="Icon-box2" type="string" value={this.state.dig7BounceMin} onChange={this.handleChange}></input>
            <input name="dig7BounceSec" className="Icon-box3" type="string" value={this.state.dig7BounceSec} onChange={this.handleChange}></input>
          </form>

          <br />
          <div>
          <div className="form-text-labels">closure</div>
          <div className="form-text-labels">accumulator</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose7' onChange={(e) => {this.setState({accClose7: !this.state.accClose7})}} checked={!this.state.accClose7} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose7' onChange={(e) => {this.setState({accClose7: !this.state.accClose7})}} checked={this.state.accClose7} />
          </div>
        </div>
      }
      {this.state.dig7 === true && this.state.digan7 === true && this.state.accClose7 === true &&
        <div>
          <br />
          <div className="form-text">Each closure equals</div>
          <form>
            <input name="dig7Value" className="Icon-box-form" type="string" value={this.state.dig7Value} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-text">reset at midnight</div>
            <input name="dig7Reset" type="checkbox" onChange={(e) => {this.setState({dig7Reset: !this.state.dig7Reset})}} checked={this.state.dig7Reset}/>
          </form>
        </div>
      }
      {this.state.dig7 === true && this.state.digan7 === true && this.state.accClose7 === false &&
        <div>
          <br />
          <div>
          <div className="form-text-labels">open</div>
          <div className="form-text-labels">closed</div>
          </div>
          <form>
          <div className="form-text-labels">{this.state.dig7Open}</div>
          <div className="form-text-labels">{this.state.dig7Closed}</div>
          </form>
        </div>
      }
      {this.state.dig7 === true && this.state.digan7 === false &&
        <div>
          <form>
            <br />
            <div className="form-text">low volt only(-2.4V to +2.4V)</div>
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan7Low" className="Analog-box" type="string" value={this.state.digan7Low} onChange={this.handleChange}></input>

              <input name="digan7High" className="Analog-box" type="string" value={this.state.digan7High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan7ScaleLow" className="Analog-box" type="string" value={this.state.digan7ScaleLow} onChange={this.handleChange}></input>

              <input name="digan7ScaleHigh" className="Analog-box" type="string" value={this.state.digan7ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="digan7Round" className="Icon-box-form" type="string" value={this.state.digan7Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      {this.state.dig8 === true &&
        <div>
          <div className="Status-wrapper">
          <h1>dig/an input 8</h1>
          </div>
          <div className="form-name">name</div>
          <input name="dig8Name" className="Reading-name" type="string" value={this.state.dig8Name.replace(/_/g, ' ')} onChange={this.handleChange}></input>
          <br />
          <br />
          <div>
          <div className="form-text-labels">digital</div>
          <div className="form-text-labels">analog</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan8' onChange={(e) => {this.setState({digan8: !this.state.digan8})}} checked={this.state.digan8} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='digan8' onChange={(e) => {this.setState({digan8: !this.state.digan8})}} checked={!this.state.digan8} />
          </div>
        </div>
      }
      {this.state.dig8 === true && this.state.digan8 === true &&
        <div>
          <form>
            <br />
            <div className="form-text">closure detected lock out time</div>
            <br />
            <div>
            <div className="form-text-labels">hours</div>
            <div className="form-text-labels">minutes</div>
            <div className="form-text-labels">seconds</div>
            </div>
            <input name="dig8BounceHour" className="Icon-box" type="string" value={this.state.dig8BounceHour} onChange={this.handleChange}></input>
            <input name="dig8BounceMin" className="Icon-box2" type="string" value={this.state.dig8BounceMin} onChange={this.handleChange}></input>
            <input name="dig8BounceSec" className="Icon-box3" type="string" value={this.state.dig8BounceSec} onChange={this.handleChange}></input>
          </form>

          <br />
          <div>
          <div className="form-text-labels">closure</div>
          <div className="form-text-labels">accumulator</div>
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose8' onChange={(e) => {this.setState({accClose8: !this.state.accClose8})}} checked={!this.state.accClose8} />&nbsp;
          </div>
          <div className="form-text-labels">
          <input type='checkbox' name='accClose8' onChange={(e) => {this.setState({accClose8: !this.state.accClose8})}} checked={this.state.accClose8} />
          </div>
        </div>
      }
      {this.state.dig8 === true && this.state.digan8 === true && this.state.accClose8 === true &&
        <div>
          <br />
          <div className="form-text">Each closure equals</div>
          <form>
            <input name="dig8Value" className="Icon-box-form" type="string" value={this.state.dig8Value} onChange={this.handleChange}></input>
            <br />
            <br />
            <div className="form-text">reset at midnight</div>
            <input name="dig8Reset" type="checkbox" onChange={(e) => {this.setState({dig8Reset: !this.state.dig8Reset})}} checked={this.state.dig8Reset}/>
          </form>
        </div>
      }
      {this.state.dig8 === true && this.state.digan8 === true && this.state.accClose8 === false &&
        <div>
          <br />
          <div>
          <div className="form-text-labels">open</div>
          <div className="form-text-labels">closed</div>
          </div>
          <form>
          <div className="form-text-labels">{this.state.dig8Open}</div>
          <div className="form-text-labels">{this.state.dig8Closed}</div>
          </form>
        </div>
      }
      {this.state.dig8 === true && this.state.digan8 === false &&
        <div>
          <form>
            <br />
            <div className="form-text">low volt only(-2.4V to +2.4V)</div>
            <br />
            <div className="form-name">voltage range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan8Low" className="Analog-box" type="string" value={this.state.digan8Low} onChange={this.handleChange}></input>

              <input name="digan8High" className="Analog-box" type="string" value={this.state.digan8High} onChange={this.handleChange}></input>
            </div>

            <br />

            <div className="form-name">scale range</div>
            <div>
              <div className="form-text-labels">low</div>

              <div className="form-text-labels">high</div>
              <div />
              <input name="digan8ScaleLow" className="Analog-box" type="string" value={this.state.digan8ScaleLow} onChange={this.handleChange}></input>

              <input name="digan8ScaleHigh" className="Analog-box" type="string" value={this.state.digan8ScaleHigh} onChange={this.handleChange}></input>
            </div>
            <br />

            <div className="form-text">round to how many decimal places</div>

            <input name="digan8Round" className="Icon-box-form" type="string" value={this.state.digan8Round} onChange={this.handleChange}></input>
          </form>
        </div>
      }

      <div className='Two-space'/>
      <div className='Two-space'/>

      { this.state.loading === false &&
        <form onSubmit={this.handleShadow}>
          <input type="submit" className="Add-button" value="submit"/>
        </form>
      }

      <br/>
      <br/>
      <br/>


      </div>
      </div>
    )
  }

}
export default DeviceSet
