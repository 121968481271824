/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "nowledgeapp-20180925132915--hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d34mpnpjwc2ro7.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-2:6b3e3127-7ada-4eb4-b609-10e167a83e76",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_JuYZpGVjZ",
    "aws_user_pools_web_client_id": "5d0e6k3lcnaaf5t1lrv5mb9h1j",
    "oauth": {},
    "aws_mobile_analytics_app_id": "4756dd3af12f4ca7a2adaf38e602737b",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "nowledgeapp8c3f822fa9044d7b8fae6374a30aac73",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "iotdata",
            "region": "us-east-2"
        },
        {
            "tableName": "Inventorydb",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://cpdoika63nfudguepwrn7uxzwi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "dataqueryapi",
            "endpoint": "https://cj2h1bq3r0.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "AthenaAPI",
            "endpoint": "https://akej8r36l4.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "ShadowApi",
            "endpoint": "https://n969ui9pyg.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "OnboardDevice",
            "endpoint": "https://movcvkmcie.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "DeviceInventory",
            "endpoint": "https://la18ghtj8d.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "organizationid",
            "endpoint": "https://kwsijc83e1.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "InventoryCheck",
            "endpoint": "https://4puwzxicik.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "monthGraphs",
            "endpoint": "https://km7mw5m9b5.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "addEnterprise",
            "endpoint": "https://lu5wf35lx0.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "EntAuth",
            "endpoint": "https://fd6dqlcv8a.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "DeviceConnectStatus",
            "endpoint": "https://9kaxoowsfk.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "monthTrimmed",
            "endpoint": "https://xoe6zwgz5b.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "DeviceDataStatus",
            "endpoint": "https://iifqmiox0f.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "SubscribeEmailAPI",
            "endpoint": "https://a5ilq9bfc8.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "AlarmList",
            "endpoint": "https://i5ycgq5b1b.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        },
        {
            "name": "Report",
            "endpoint": "https://rtlx2yey01.execute-api.us-east-2.amazonaws.com/Prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
