import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import DashOffline from '../components/DashOffline';
import DashAlarms from '../components/DashAlarms';
import DashNotes from '../components/DashNotes.js';

class Dashboard extends Component {
    constructor(props){
        super(props)

        this.firstFetch = true
            
        this.state = {
            devices: [],
            group: '',
            alarmsArray: [],
            offlineDevices: [],
            offlineCount: '',
            onlineCount: '',
            notes: [],
            loading: true
        }
        this.fetchAllData = this.fetchAllData.bind(this)
        this.stopInterval = this.stopInterval.bind(this);
    }


    // clear interval for the data call for new data
    stopInterval(event) {
        clearInterval(this.interval);
    }

    UNSAFE_componentWillUnmount() {
        //cant perform react state update on unmounted component
        clearInterval(this.interval);
            this.setState = (state,callback)=>{
                return;
            };
    }

    async fetchAllData(){
        let devices = this.devices

        let dateNow = new Date();
        let minLate = 600000;

        let hour = dateNow.getHours()
        let minutes = dateNow.getMinutes()

        let offlineArray = [];
        let onlineArray = [];
        let serialArray = []

        // array of just serial numbers for latest data api
        devices.forEach(unit => {
            serialArray.push(unit.location)
        })
        let serialArrayString = "[" + serialArray.map(serial => `"${serial}"`).join(',') + "]"


        // api send array of serial number returns the last logged data for every device
        function getLatestData() {
            const apiName = 'DeviceDataStatus';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                    name: serialArrayString
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let responseData = {};
        try {
            responseData = await getLatestData()
        } catch(error) {
            console.log(error)
            if(error.message === 'Network Error'){
                this.setState({networkError: true})
                return
            } else {
                return
            }
        };
        if (responseData.result === 'error') {
            return
        }

        let finalDeviceData = responseData.return

        if(finalDeviceData.length === 0){
            console.log('get latest data api returned zero alarms')
        }

        // get all parent db id number create an array with id numbers and device information
        let serialIdArray = [];
        let cleanedBlogIds = []
        
        let field = "location"

        let filterArray = serialArray.map((item)  => JSON.parse(`{"${field}":{"eq":"${item}"}}`))

        if(filterArray.length > 100){
            let array1 = filterArray.slice(0,100)
            let array2 = filterArray.slice(100)
            let finalFilter1 = {or:array1}
            let finalFilter2 = {or:array2}

            let blogIds1 = []
            let blogIds2 = []
            try{
                blogIds1 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter1}));
            } catch(error){
                console.log(error)
            }
            try{
                blogIds2 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter2}));
            } catch(error){
                console.log(error)
            }
            try {
                let cleanedBlogIds1 = blogIds1.data.listBlogs.items
                let cleanedBlogIds2 = blogIds2.data.listBlogs.items
                cleanedBlogIds = cleanedBlogIds1.concat(cleanedBlogIds2)
            } catch(error){
                console.log(error)
            }



        } else {
            let finalFilter = {or:filterArray}
            let testBlogIds = []
            try{
                testBlogIds = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter}));
            } catch(error){
                console.log(error)
            }       
            cleanedBlogIds = testBlogIds.data.listBlogs.items
        }
        
        if(cleanedBlogIds.length === 0){
            console.log('cleaned blog ids is zero')
        }
        
    
        // new cleaned array for blog IDS
         await devices.forEach(device => {
            cleanedBlogIds.forEach(idDevice => {
                if(device.location === idDevice.location){
                    serialIdArray.push({'serial': device.location, 'group': device.group, 'title': device.title, 'blogID': idDevice.id, 'version': device.version})
                }
            })
        })
        
        if(serialIdArray.lenght === 0){
            console.log('serial id array is zero')
        }
        
        let iconsArray = []
        // get all icons attach to device information as an array
        let getIcons = async() => {
            await Promise.all(serialIdArray.map(async (object) => {
                const getIcons = `query GetIcons { getBlog(id:"${object.blogID}") {id location icons { items { value lowest highest order }}}}`
                let iconData = await API.graphql(graphqlOperation(getIcons));
                iconsArray.push({'serial': object.serial, 'group': object.group, 'title': object.title, 'version': object.version, 'icons': iconData.data.getBlog.icons.items})
            }))
        }
        try {
            await getIcons()
        } catch(error)  {
            console.log(error)
        }

        if(iconsArray.length === 0){
            console.log('iconsArray is zero')
        }

        let finalAlarmArray = []
        let alarmCaught = false
        let alarmCounter = 0

        // match icon information to latest device data create array of readings out of range
        iconsArray.forEach(device => {
            alarmCaught = false
            let alarmCaughtArray = []
            finalDeviceData.forEach(deviceData => {               
                try {
                    if(deviceData.device === device.serial){
                        if(deviceData.device_status === "input board not found"){
                            alarmCounter++
                            alarmCaughtArray.push({'name': '', 'reading': 'no input board', 'info': ''})
                            alarmCaught = true
                        }
                        device.icons.forEach(deviceIcon => {
                            Object.entries(deviceData).map(reading => {
                                if(deviceIcon.value === reading[0]){
                                    if(reading[1] > deviceIcon.highest || reading[1] < deviceIcon.lowest ){
                                        let tempInfo = ''
                                        alarmCounter++
                                        if(reading[1] > deviceIcon.highest){
                                            tempInfo = `above ${deviceIcon.highest}`
                                        }
                                        if(reading[1] < deviceIcon.lowest){
                                            tempInfo = `below ${deviceIcon.lowest}`
                                        }
                                        alarmCaughtArray.push({'name': reading[0], 'reading': reading[1], 'info': tempInfo})
                                        alarmCaught = true
                                    }
                                }
                                return alarmCaught
                            })
                        })
                    }
                } catch(error) {
                    
                }              
            })
            if(alarmCaught === true){
                finalAlarmArray.push({'serial': device.serial, 'name': device.title, 'group': device.group, 'title': device.title, 'version': device.version, 'alarms': alarmCaughtArray})
            }
        })

        if(finalAlarmArray.length === 0){
            console.log('finalAlarmArray is zero when matching readings to icon data')
        }

        // fetch user group
        let group = await API.graphql(
            graphqlOperation(queries.getUser, { userName: this.userName })
        );
        this.groupName = group.data.getUser.groupName;

        const hiddenAlarms = `query GetHidden {
            getOrganizationLink(orgName: "${this.groupName}") {
                id
                    hiddenAlarms {
                        items {
                            groupName
                            readingName
                            serial
                            subGroup
                            deviceName
                        }
                    }
            }
        }`

    
        let hiddenAlarmsArray = []
        // fetch hidden alarms attached to users group
        try{
            let hiddenAlarmsFetched = await API.graphql(graphqlOperation(hiddenAlarms));
            hiddenAlarmsArray = hiddenAlarmsFetched.data.getOrganizationLink.hiddenAlarms.items
        } catch(error) {
            console.log(error)
        };

        let trimmedFinalAlarmArray = []

        // trim hidden alarms
        finalAlarmArray.forEach(device => {
            if(hiddenAlarmsArray.some(i => i.serial.includes(device.serial))){
                let displayAlarmsArray = []
                device.alarms.forEach(alarm => {
                    let found = hiddenAlarmsArray.some( e => e.serial === device.serial && e.readingName === alarm.name)
                        if(!found){
                            displayAlarmsArray.push({'name': alarm.name, 'reading': alarm.reading, 'info': alarm.info})
                        } else {
                            alarmCounter--
                        }
                })
                if(displayAlarmsArray.length !== 0){
                    trimmedFinalAlarmArray.push({'serial': device.serial, 'name': device.deviceName, 'group': device.group, 'title': device.title, 'version': device.version, 'alarms': displayAlarmsArray})
                }
            } else {
                trimmedFinalAlarmArray.push(device)
            }
        })

        if(trimmedFinalAlarmArray.length === 0){
            console.log('trimmedFinalAlaryArray is zero this is after trimming hidden alarms')
        }


        // order alph device name then group
        let orderAlarmdevice = trimmedFinalAlarmArray.sort((a, b) => a.serial.localeCompare(b.serial, undefined, { numeric: true }))
        let orderedAlarmArray = orderAlarmdevice.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))
        let readingCountHeld = alarmCounter

        // api to fetch all devices last reading for the timestamp
        function getTimestamps() {
            const apiName = 'DeviceConnectStatus';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                  name: serialArrayString
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let response = {};
        let timestampsArray = []
        try {
            response = await getTimestamps()
            timestampsArray = response.return

        } catch(error) {
            console.log(error)
            if(error.message === 'Network Error'){
                return
            } else {
                return
            }
        };
        if (response.result === 'error') {
            return
        }

        let offlineArraySorted = []
        let offlineDeviceHeld = ''
        let onlineDeviceHeld = ''

        // check the current time by the adjusted for time zone timestamps returned
        timestampsArray.forEach( timestampData => {
            let deviceOnline = ''
            let modTimestamp = ''
            try{
                if(this.timeZone === 'America/Chicago'){
                    modTimestamp = timestampData.timestamps.replace(/\s/, 'T')
                } else {
                    let dateObject = new Date(timestampData.timestamps)
                    let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
                    let monthTest = changedStamp.slice(3,5)
                    let dayTest = changedStamp.slice(0,2)
                    let yearTest = changedStamp.slice(6,10)
                    let hourTest = changedStamp.slice(12,14)
                    let minuteTest = changedStamp.slice(15,17)
                    let milliSeconds = changedStamp.slice(18,20)
            
                    modTimestamp = yearTest + "-" + monthTest + "-" + dayTest + "T" + hourTest + ":" + minuteTest + ":" + milliSeconds
                }
                
                if((dateNow - new Date(modTimestamp)) > minLate) {
                    deviceOnline = false;
                }
                else{
                    deviceOnline = true;
                }

                devices.forEach( deviceData => {
                    if(timestampData.device === deviceData.location){
                        let newObject = {'serial': deviceData.location, 'organization': deviceData.group, 'nickname': deviceData.title, 'timestamp': timestampData.timestamps, 'version': deviceData.version}
                        if(!deviceOnline){
                            offlineArray.push(newObject)
                        } else {
                            onlineArray.push(newObject)
                        }
                    }
                })
                
            } catch(error) {
                if (error instanceof TypeError) {
                    //console.log('null')
                }
                else {
                    console.log(error)
                }
            }


        })

        let trimmedOfflineArray = []
        let trimmedOnlineArray = []

        // remove hidden offline/online status for matching devices
        offlineArray.forEach(device => {
            let found = hiddenAlarmsArray.some( e => e.serial === device.serial && e.readingName === 'offline_hide_alarm_check')
            if(!found){
                trimmedOfflineArray.push(device)
            } 
        })

        offlineArraySorted = trimmedOfflineArray.sort((a, b) => b.timestamp.localeCompare(a.timestamp, undefined, { numeric: true }))
        offlineDeviceHeld = offlineArraySorted.length

        
        onlineArray.forEach(device => {
            let found = hiddenAlarmsArray.some( e => e.serial === device.serial && e.readingName === 'offline_hide_alarm_check')
            if(!found){
                trimmedOnlineArray.push(device)
            } 
        })

        onlineDeviceHeld = trimmedOnlineArray.length
        

        let serialIdArrayNotes = []

        // fetch newest notes
        devices.forEach(device => {
            cleanedBlogIds.forEach(idDevice => {
                if(device.location === idDevice.location){
                    serialIdArrayNotes.push({'serial': device.location, 'group': device.group, 'title': device.title, 'blogID': idDevice.id, 'version': device.version})
                }
            })
        })

        
        // fetch notes and match to device information seperate devices with notes from those without
        let matchedNotesArray = []
        let matchedNoNotesArray = []

        let getNotesMatch = async() => {
            await Promise.all(serialIdArrayNotes.map(async (object) => {
                let getNotes = `query GetConvo { getBlog(id:"${object.blogID}") { id location posts(sortDirection:DESC limit: 1) { items { id author content status createdAt img } } } }` 
                let notesData = await API.graphql(graphqlOperation(getNotes));
                if(notesData.data.getBlog.posts.items.length === 0){
                    let newNoneObject = {'serial': object.serial, 'group': object.group, 'title': object.title, 'blogID': object.blogID, 'version': object.version, timestamp: '0'}
                    matchedNoNotesArray.push(newNoneObject)
                } else {
                    let newNotesObject = {'serial': object.serial, 'group': object.group, 'title': object.title, 'blogID': object.blogID, 'version': object.version, timestamp: notesData.data.getBlog.posts.items[0].createdAt, author: notesData.data.getBlog.posts.items[0].author, content: notesData.data.getBlog.posts.items[0].content, status: notesData.data.getBlog.posts.items[0].status, image: notesData.data.getBlog.posts.items[0].img}
                    matchedNotesArray.push(newNotesObject)
                }
            }))
        }
        try {
            await getNotesMatch()
        } catch(error)  {
            console.log(error)
        }

        // order seperated notes
        let orderedNotes = matchedNotesArray.sort((a, b) => b.timestamp.localeCompare(a.timestamp, undefined, { numeric: true }))
        let limitedNotes = orderedNotes.slice(0,10)

        this.setState({
            loading: false,
            alarmsArray: orderedAlarmArray,
            readingCount: readingCountHeld,
            offlineDevices: offlineArraySorted,
            offlineCount: offlineDeviceHeld,
            onlineCount: onlineDeviceHeld,
            notes: limitedNotes,
            networkError: false
        })

    
        // refresh at noon
        if(hour === 12 && minutes === 0 && this.firstFetch === false){
            window.location.reload(true)
        }

        // toggle after the first data fetch on mount
        this.firstFetch = false

    }

    async componentDidMount() {
        this.devices = []

        Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                this.loggedIn = true
            }
            )
            .catch(error => {
                if(error === 'not authenticated'){
                    this.props.history.push('/SignIn');
                }
            }
        );

        let user = await Auth.currentAuthenticatedUser();
        this.userName = user.username
        const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];

        let groupCheck = userGroup[0];
        this.groupCheck = userGroup[0]

        this.interval = setInterval(this.fetchAllData, 60000);

        // fetch user devices routing through home is not necessary on device boot
        if (groupCheck === "enerstar"){
            try {
                let data = await API.graphql(graphqlOperation(queries.listDevices, { limit: 1000 }));
                let sortedSerial = data.data.listDevices.items.sort((a, b) => a.location.localeCompare(b.location, undefined, { numeric: true }))
                let sortedDevices = sortedSerial.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))
                this.devices = sortedDevices

            } catch(error) {
                console.log(error)
                
            }
        }

        if (groupCheck === "multiloc"){
            let queryUpper = this.userName

            let queryName = queryUpper.toLowerCase()

            try{
                let data = await API.graphql(graphqlOperation(queries.listMultidevices, {
                    limit: 1000,
                    filter: {
                        group:{
                            eq: queryName
                        }
                    }
                }));

                data.data.listMultidevices.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                this.devices = data.data.listMultidevices.items
                
            } catch(error) {
                console.log(error)
            }
        }

        //check for users timezone offset compared to central times offset
        let changedCentral = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Chicago' }).format()
        let offsetCentral = Number(changedCentral.slice(-1))
        let localDate = new Date()
        let localOffset = localDate.getTimezoneOffset() / 60

        if(offsetCentral === localOffset){
            this.timeZone = 'America/Chicago'

        } else {
            let offsetDifference = offsetCentral - localOffset
            if(offsetDifference === 1){
                this.timeZone = 'America/Halifax'
            }
            if(offsetDifference === -1){
                this.timeZone = 'America/Los_Angeles'
            }
            if(offsetDifference === -2){
                this.timeZone = 'America/Anchorage'
            }
        }

        this.fetchAllData()

    }

    render(){
        return(
            <div className='App'>
                {this.state.loading &&
                    <div>
                        <div className='Two-space'/>                       
                            <div className='One-space'/>
                            <div className='title-two'>Loading...</div>
                            <div className='One-space'/>                       
                    </div>
                }
                {!this.state.loading &&
                    <div className='base-layout'>
                        <div className='col-1'>
                            <DashOffline
                                devices={this.state.offlineDevices}
                                count={this.state.offlineCount}
                                online={this.state.onlineCount}
                                group={this.groupCheck}
                            />
                        </div>
                        <div className='col-2'>
                            <DashAlarms
                                data={this.state.alarmsArray}
                                count={this.state.readingCount}
                                group={this.groupCheck}
                                networkError={this.state.networkError}
                            />
                        </div>
                        <div className='col-3'>
                            <DashNotes
                                notes={this.state.notes}
                                group={this.groupCheck}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Dashboard